import React, { FunctionComponent } from 'react'
import { Dialog } from './Dialog'

type Props = {
  onClose: () => void
  onConfirm: () => void
  message: string
  header: string
}

export const ConfirmActionDialog: FunctionComponent<Props> = ({ onConfirm, onClose, header, message }) => {
  return (
    <Dialog visible={true} onClose={onClose} shouldCloseOnOverlayClick>
      <div className="p-lg">
        <h1 className="text-xl mb-md">{header}</h1>
        <p>{message}</p>
        <div className="flex space-x-md justify-end mt-lg">
          <button className="btn" onClick={onClose}>
            Cancel
          </button>
          <button className="btn btn-primary-danger" onClick={onConfirm}>
            Delete
          </button>
        </div>
      </div>
    </Dialog>
  )
}
