import React from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { AddedListContainer, ChangedListContainer, DiffTabs, InvalidList, RemovedListContainer, Step } from './diffing'
import { spacing } from '../../styles'
import type { DiffDataStore } from '../../stores'
import { ReactComponent as ArrowLeftIcon } from '@tomra/design-system/src/config/icons/arrow-left.svg'
import { withRouter } from '../../lib/withRouter'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    width: 100%;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.md};
`

const OverflowCard = styled.div`
  display: inline-block;
  white-space: nowrap;
`

type Props = {
  diffDataStore: DiffDataStore
}

type State = {
  currentStep: Step
}

export class DiffContainerPure extends React.Component<Props, State> {
  state: Readonly<State> = {
    currentStep: 'added'
  }

  _updateCurrentStep = (newStep: Step) => {
    this.setState({ currentStep: newStep })
  }

  render() {
    const { addedDiff, changedDiff, removedDiff, validationMessages } = this.props.diffDataStore
    const { currentStep } = this.state

    return (
      <Container>
        <HeaderContainer>
          <Link to="/">
            <ArrowLeftIcon className="inline-block" width="1rem" height="1rem" />
          </Link>
          <DiffTabs
            updateCurrentStep={this._updateCurrentStep}
            currentStep={currentStep}
            numOfAdded={addedDiff.length}
            numOfChanged={changedDiff.length}
            numOfRemoved={removedDiff.length}
            numOfInvalid={validationMessages.length}
          />
          <div />
        </HeaderContainer>
        <div>
          {currentStep === 'added' && (
            <OverflowCard className={'card'}>
              <AddedListContainer />
            </OverflowCard>
          )}
          {currentStep === 'changed' && (
            <OverflowCard className={'card'}>
              <ChangedListContainer />
            </OverflowCard>
          )}
          {currentStep === 'removed' && (
            <OverflowCard className={'card'}>
              <RemovedListContainer />
            </OverflowCard>
          )}
          {currentStep === 'invalid' && (
            <OverflowCard className={'card'}>
              <InvalidList messages={validationMessages} />
            </OverflowCard>
          )}
        </div>
      </Container>
    )
  }
}

export const DiffContainer = withRouter(inject('diffDataStore')(observer(DiffContainerPure)))
