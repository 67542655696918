import { action, extendObservable } from 'mobx'
import debounce from 'lodash/debounce'

import { containerColumnMap, fromInputPerField } from './containerColumnMap'

import { ContainersDataStore } from './ContainersDataStore'
import type { Container, Order, ContainerColumn } from '../dataTypes'

export class ContainersUiState {
  // Observables
  recordsPerPage!: number
  columnFilters!: { [columnName: string]: string }
  mappedColumnFilters!: { [columnName: string]: any }
  sortedColumn!: string
  order!: Order
  paginationOffset!: number
  currentPage!: number
  numberOfPages!: number
  selectedContainers!: Container[]
  columns!: Array<ContainerColumn>
  statusFilter!: 'All' | 'Active' | 'Inactive'

  // Actions
  fetchContainersWithCurrentSettings!: Function
  updateRecordsPerPage!: Function
  changePage!: Function
  updateSortedColumn!: Function
  checkIfSelected!: (containerToCheck: Container) => boolean
  toggleAllContainers!: Function
  selectContainer!: Function
  resetSelection!: Function
  updateColumnFilter!: Function
  updateStatusFilter!: Function
  toggleColumn!: Function

  // Helper functions
  _setSelected!: Function

  constructor(dataStore: ContainersDataStore) {
    extendObservable(this, {
      recordsPerPage: 30,
      columnFilters: { active: 'yes' },
      sortedColumn: 'productName',
      order: 'asc',
      paginationOffset: 0,
      currentPage: 1,
      selectedContainers: [],
      columns: [...containerColumnMap],
      statusFilter: 'Active',

      _setSelected: action(newSelectedContainers => {
        this.selectedContainers = newSelectedContainers
      }),

      get numberOfPages() {
        return Math.ceil(dataStore.totalCount / this.recordsPerPage)
      },

      get mappedColumnFilters() {
        return Object.entries(this.columnFilters)
          .filter(([_, filterValue]) => !!filterValue)
          .reduce((acc, pair) => {
            const [columnName, filterValue] = pair
            const filterValueMapper = fromInputPerField[columnName]
            const mappedValue = filterValueMapper(filterValue)
            return { ...acc, [columnName]: mappedValue }
          }, {})
      },

      checkIfSelected: (containerToCheck: Container) => {
        return this.selectedContainers.some(container => container.id === containerToCheck.id)
      },

      fetchContainersWithCurrentSettings: debounce(
        () => {
          dataStore.fetchContainers(
            this.mappedColumnFilters,
            this.sortedColumn,
            this.order,
            this.paginationOffset,
            this.recordsPerPage
          )
        },
        1000,
        { leading: true, trailing: true }
      ),

      updateRecordsPerPage: action(recordsPerPage => {
        this.recordsPerPage = recordsPerPage
        this.fetchContainersWithCurrentSettings()
      }),

      changePage: action(newPage => {
        newPage > this.currentPage
          ? (this.paginationOffset = this.paginationOffset + this.recordsPerPage)
          : (this.paginationOffset = this.paginationOffset - this.recordsPerPage)
        this.currentPage = newPage

        this.fetchContainersWithCurrentSettings()
      }),

      updateSortedColumn: action((order: Order, newSortedColumn: string) => {
        this.sortedColumn = newSortedColumn
        this.order = order

        this.fetchContainersWithCurrentSettings()
      }),

      updateColumnFilter: action((filterValue, columnName) => {
        if (this.columnFilters[columnName] === undefined) {
          this.columnFilters[columnName] = '' // initialize column filter to avoid 'changes' from undefined to empty string
        }
        if (filterValue !== this.columnFilters[columnName]) {
          this.columnFilters = { ...this.columnFilters, [columnName]: filterValue }
          this.fetchContainersWithCurrentSettings()
        }
      }),

      updateStatusFilter: action(value => {
        this.statusFilter = value

        if (value === 'All') delete this.columnFilters.active
        else this.columnFilters = { ...this.columnFilters, active: value === 'Active' ? 'yes' : 'no' }

        this.fetchContainersWithCurrentSettings()
      }),

      toggleAllContainers: () => {
        if (!dataStore.containers.every(this.checkIfSelected)) {
          this._setSelected(dataStore.containers.slice(0))
        } else this._setSelected([])
      },

      resetSelection: () => {
        this._setSelected([])
      },

      selectContainer: selectedContainer => {
        const isCurrentlySelected = selectedContainer && this.checkIfSelected(selectedContainer)
        const newSelection = isCurrentlySelected
          ? this.selectedContainers.filter(container => container.id !== selectedContainer.id)
          : [...this.selectedContainers, selectedContainer]
        this._setSelected(newSelection)
      },

      toggleColumn: action(fieldName => {
        const updatingColumnIndex = this.columns.findIndex(column => column.fieldName === fieldName)
        this.columns[updatingColumnIndex].visible = !this.columns[updatingColumnIndex].visible
      })
    })
  }
}
