import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { ChangedList } from './ChangedList'

import type { ContainersDataStore, ContainersUiState, DiffDataStore } from '../../../../stores'
import type { Order } from '../../../../dataTypes'
import { IReactComponent } from 'mobx-react/dist/types/IReactComponent'

type Props = {}

type PropsPure = Props & {
  diffDataStore: DiffDataStore
  containersUiState: ContainersUiState
  containersDataStore: ContainersDataStore
}

type State = {
  sortedColumn?: string
  order?: Order
}

export class ChangedListContainerPure extends Component<PropsPure, State> {
  state: Readonly<State> = {
    sortedColumn: 'productName',
    order: 'desc'
  }

  _updateSortedColumn = (order, columnName) => {
    this.props.diffDataStore.changedSort(order, columnName)
    this.setState({ order: order, sortedColumn: columnName })
  }

  render() {
    const { changedDiff, removeContainerFromChangedDiff } = this.props.diffDataStore
    const { columns, toggleColumn } = this.props.containersUiState
    const { editContainer, validValuesOf } = this.props.containersDataStore
    const { sortedColumn, order } = this.state

    return (
      <ChangedList
        changedContainers={changedDiff}
        updateSortedColumn={this._updateSortedColumn}
        sortedColumn={sortedColumn}
        order={order}
        columns={columns}
        toggleColumn={toggleColumn}
        editContainer={editContainer}
        removeContainerFromChangedDiff={removeContainerFromChangedDiff}
        validValuesOf={validValuesOf}
      />
    )
  }
}

export const ChangedListContainer = inject(
  'diffDataStore',
  'containersUiState',
  'containersDataStore'
)(observer(ChangedListContainerPure)) as unknown as IReactComponent<Props>
