import React, { Component, ReactNode } from 'react'
import styled from 'styled-components'

import { spacing, colors } from '../../../styles'
import { Collapsible } from './Collapsible'
import {
  ActiveInput,
  CapacityInput,
  DiameterInput,
  DiameterOverrideInput,
  GlassColorInput,
  GlassColorOtherInput,
  HeightInput,
  HeightOverrideInput,
  LaunchDateInput,
  MaterialTypeInput,
  MaterialTypeOtherInput,
  NewProductInput,
  NswLogoInput,
  PetColorInput,
  ProductGroupInput,
  ProductGroupOtherInput,
  ProductNameInput,
  RegistrationStatusInput,
  SanInput,
  SantLogoInput,
  ShapeGroupInput,
  SortCodeInput,
  SourceReadonlyInput,
  SupplierNameInput,
  ToleranceClassInput,
  WeightInput
} from './ContainerFormComponents'

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  .collapsible-header {
    padding-left: ${spacing.xl};
    color: ${colors.PRIMARY_TEXT};
    font-weight: 400;
    font-size: 1.5em;
  }
`

const CollapsibleWrapper = styled.div`
  border-top: solid 1px ${colors.LIGHT_GREY};
  border-bottom: solid 1px ${colors.LIGHT_GREY};
  margin-bottom: ${spacing.lg};
  padding-top: ${spacing.xs};
  padding-bottom: ${spacing.xs};
  background-color: ${colors.LIGHTER_GREY};
`

const BarCode = styled.span`
  color: ${colors.PRIMARY_TEXT};
  font-weight: 400;
  font-size: 1.5em;
`

const InputGroupHeader = styled.h3`
  padding-left: ${spacing.xl};
  margin-bottom: 0px;
`

const InnerContainer = styled.div`
  padding-left: ${spacing.xl};
  margin-bottom: ${spacing.md};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1100px;
  > * {
    margin-right: ${spacing.md};
  }
  > *:last-child {
    margin-right: ${spacing.xl};
  }
`

type MapOfFieldNameAndValues = {
  [fieldName: string]: any
}

type Props = {
  children?: ReactNode
  initialFieldValues: MapOfFieldNameAndValues
  onSubmit: (data: MapOfFieldNameAndValues) => void | Promise<any>
  validValuesOf: (fieldName: string) => string[]
}

type State = {
  fields: MapOfFieldNameAndValues
}

export class EditContainerForm extends Component<Props, State> {
  _barcodeElement: HTMLInputElement | undefined

  constructor(props: Props) {
    super(props)

    this.state = {
      fields: props.initialFieldValues || {}
    }
  }

  componentDidMount() {
    this._barcodeElement && this._barcodeElement.focus()
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.initialFieldValues && this.props.initialFieldValues !== prevProps.initialFieldValues) {
      this.setState({ fields: this.props.initialFieldValues })
    }
  }

  _provideOnSubmitWithFields = evt => {
    // evt is not defined when running tests
    evt && evt.preventDefault()

    this.props.onSubmit(this.state.fields)
  }

  _setFieldValueIntoState = (name, value) => {
    this.setState(prevState => {
      if (prevState.fields[name] !== value) {
        const newFields = { ...prevState.fields, [name]: value }
        return { fields: newFields }
      } else {
        return null
      }
    })
  }

  _setNumericFieldValueIntoState = (name, stringValue) => {
    const parsedValue = stringValue ? parseInt(stringValue, 10) : null
    this._setFieldValueIntoState(name, parsedValue)
  }

  render() {
    const {
      source,
      productName,
      supplierName,
      productGroup,
      productGroupOther,
      san,
      materialType,
      materialTypeOther,
      glassColour,
      glassColourOther,
      petColour,
      heightWithCapMm,
      heightOverride,
      largestDiameterMm,
      diameterOverride,
      weightEmptyGm,
      capacityMl,
      toleranceClass,
      nswRefundLogo,
      santRefundLogo,
      shapeGroup,
      sortCode,
      active,
      registrationStatus,
      newProduct,
      newProductLaunchDate
    } = this.state.fields

    const {
      validValuesOf,
      children,
      initialFieldValues: { barcode }
    } = this.props

    return (
      <Form onSubmit={this._provideOnSubmitWithFields} data-testid="edit-container-form">
        <InputGroupHeader>Edit barcode</InputGroupHeader>

        <InnerContainer>
          <BarCode>{barcode}</BarCode>
          <ActiveInput currentValue={active} setField={this._setFieldValueIntoState} />
        </InnerContainer>

        <InputGroupHeader>Container</InputGroupHeader>
        <InnerContainer>
          <SourceReadonlyInput currentValue={source} />

          <ProductNameInput currentValue={productName} setField={this._setFieldValueIntoState} size={40} required />
        </InnerContainer>

        <InnerContainer>
          <MaterialTypeInput
            currentValue={materialType}
            setField={this._setFieldValueIntoState}
            validValuesOf={validValuesOf}
          />
        </InnerContainer>

        <InnerContainer>
          <HeightInput currentValue={heightWithCapMm} setField={this._setNumericFieldValueIntoState} required />
          <HeightOverrideInput currentValue={heightOverride} setField={this._setNumericFieldValueIntoState} />
        </InnerContainer>

        <InnerContainer>
          <WeightInput currentValue={weightEmptyGm} setField={this._setNumericFieldValueIntoState} required />
        </InnerContainer>

        <InnerContainer>
          <DiameterInput currentValue={largestDiameterMm} setField={this._setNumericFieldValueIntoState} required />
          <DiameterOverrideInput currentValue={diameterOverride} setField={this._setNumericFieldValueIntoState} />
        </InnerContainer>

        <InnerContainer>
          <CapacityInput currentValue={capacityMl} setField={this._setNumericFieldValueIntoState} required />
        </InnerContainer>

        <InputGroupHeader>Handling</InputGroupHeader>
        <InnerContainer>
          <ToleranceClassInput currentValue={toleranceClass} setField={this._setNumericFieldValueIntoState} />
          <ShapeGroupInput currentValue={shapeGroup} setField={this._setNumericFieldValueIntoState} />
          <SortCodeInput currentValue={sortCode} setField={this._setNumericFieldValueIntoState} />
        </InnerContainer>

        <CollapsibleWrapper>
          <Collapsible title="EPA specific" titleClassName="collapsible-header flex items-center">
            <InputGroupHeader>Basic information</InputGroupHeader>
            <InnerContainer>
              <SupplierNameInput currentValue={supplierName} setField={this._setFieldValueIntoState} />
            </InnerContainer>

            <InnerContainer>
              <ProductGroupInput
                currentValue={productGroup}
                setField={this._setFieldValueIntoState}
                validValuesOf={validValuesOf}
              />
              <ProductGroupOtherInput currentValue={productGroupOther} setField={this._setFieldValueIntoState} />
              <SanInput currentValue={san} setField={this._setFieldValueIntoState} />
            </InnerContainer>

            <InputGroupHeader>Container</InputGroupHeader>
            <InnerContainer>
              <MaterialTypeOtherInput currentValue={materialTypeOther} setField={this._setFieldValueIntoState} />
            </InnerContainer>

            <InnerContainer>
              <GlassColorInput
                currentValue={glassColour}
                validValuesOf={validValuesOf}
                setField={this._setFieldValueIntoState}
              />
              <GlassColorOtherInput currentValue={glassColourOther} setField={this._setFieldValueIntoState} />
            </InnerContainer>

            <InnerContainer>
              <PetColorInput
                currentValue={petColour}
                validValuesOf={validValuesOf}
                setField={this._setFieldValueIntoState}
              />
            </InnerContainer>

            <InnerContainer>
              <NswLogoInput currentValue={nswRefundLogo} setField={this._setFieldValueIntoState} />
            </InnerContainer>

            <InnerContainer>
              <SantLogoInput currentValue={santRefundLogo} setField={this._setFieldValueIntoState} />
            </InnerContainer>

            <InputGroupHeader>Handling</InputGroupHeader>
            <InnerContainer>
              <RegistrationStatusInput
                currentValue={registrationStatus}
                setField={this._setFieldValueIntoState}
                validValuesOf={validValuesOf}
              />
            </InnerContainer>

            <InputGroupHeader>Registration Information</InputGroupHeader>

            <InnerContainer>
              <NewProductInput currentValue={newProduct} setField={this._setFieldValueIntoState} />
            </InnerContainer>

            <InnerContainer>
              <LaunchDateInput currentValue={newProductLaunchDate} setField={this._setFieldValueIntoState} />
            </InnerContainer>
          </Collapsible>
        </CollapsibleWrapper>
        {children}
      </Form>
    )
  }
}
