import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { AddedList } from './AddedList'

import type { ContainersDataStore, ContainersUiState, DiffDataStore } from '../../../../stores'
import type { Order } from '../../../../dataTypes'
import { IReactComponent } from 'mobx-react/dist/types/IReactComponent'

type Props = {}

type PropsPure = Props & {
  diffDataStore: DiffDataStore
  containersUiState: ContainersUiState
  containersDataStore: ContainersDataStore
}

type State = {
  sortedColumn: string
  order?: Order
}

export class AddedListContainerPure extends Component<PropsPure, State> {
  state: Readonly<State> = {
    sortedColumn: 'productName',
    order: 'desc'
  }

  _updateSortedColumn = (order, columnName) => {
    this.props.diffDataStore.addedSort(order, columnName)
    this.setState({ order: order, sortedColumn: columnName })
  }

  render() {
    const { addedDiff, removeContainerFromAddedDiff } = this.props.diffDataStore
    const { columns, toggleColumn } = this.props.containersUiState
    const { addContainer, addContainerStatuses, validValuesOf } = this.props.containersDataStore
    const { sortedColumn, order } = this.state

    return (
      <AddedList
        addedContainers={addedDiff}
        updateSortedColumn={this._updateSortedColumn}
        sortedColumn={sortedColumn}
        order={order}
        columns={columns}
        toggleColumn={toggleColumn}
        addContainer={addContainer}
        addContainerStatuses={addContainerStatuses}
        removeContainerFromAddedDiff={removeContainerFromAddedDiff}
        validValuesOf={validValuesOf}
      />
    )
  }
}

export const AddedListContainer = inject(
  'diffDataStore',
  'containersUiState',
  'containersDataStore'
)(observer(AddedListContainerPure)) as unknown as IReactComponent<Props>
