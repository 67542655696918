import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'

import { AddedRow } from './AddedRow'
import { spacing } from '../../../../styles'

import type { Container, ContainerColumn, Order, RequestProgress } from '../../../../dataTypes'
import { SortableTh } from '../../containers/SortableTh'
import { TableHeader } from '../../containers/TableHeader'
import { ColumnSelect } from '../../containers/ColumnSelect'
import { ReactComponent as MenuHorizontal } from '@tomra/design-system/src/config/icons/menu-horizontal.svg'

const ColumnSelectContainer = styled.div`
  position: relative;
  > button {
    padding: 0;
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    z-index 1;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  > *:not(:last-child) {
    margin-right: ${spacing.md};
  }
`

const TableHeaderIconButton = styled.button`
  color: white;
`

const FixedHeaderWidthTable = styled.table`
  table-layout: auto;
  th {
    min-width: 70px;
    max-width: none;
  }

  td {
    max-width: none;
  }
`

export type Props = {
  addedContainers: Container[]
  updateSortedColumn: Function
  sortedColumn?: string
  order?: Order
  columns: ContainerColumn[]
  toggleColumn: Function
  addContainer: (container: Container) => Promise<void>
  addContainerStatuses: { [id: string]: RequestProgress }
  removeContainerFromAddedDiff: (string) => void
  validValuesOf: Function
}

type State = {
  showColumnSelect: boolean
  selectedContainers: Container[]
}

export class AddedListPure extends React.Component<Props, State> {
  state: Readonly<State> = {
    showColumnSelect: false,
    selectedContainers: []
  }

  _toggleColumnSelect = () => {
    this.setState(prevState => {
      return { showColumnSelect: !prevState.showColumnSelect }
    })
  }

  _changeContainerSelected = (selected, container) => {
    if (selected) {
      this.setState(prevState => ({ selectedContainers: prevState.selectedContainers.concat([container]) }))
    } else {
      this.setState(prevState => ({
        selectedContainers: prevState.selectedContainers.filter(c => c.barcode !== container.barcode)
      }))
    }
  }

  _toggleAllContainers = event => {
    if (event.target.checked) {
      this.setState({ selectedContainers: this.props.addedContainers })
    } else {
      this.setState({
        selectedContainers: []
      })
    }
  }

  _isSelected(barcode) {
    return this.state.selectedContainers.findIndex(c => c.barcode === barcode) !== -1
  }

  _addSelectedContainers = () => {
    return this.state.selectedContainers
      .reduce((acc, container) => {
        return acc.then(() => {
          return this.props.addContainer(container).then(() => {
            this.props.removeContainerFromAddedDiff(container.id)
          })
        })
      }, Promise.resolve())
      .then(() => this.setState({ selectedContainers: [] }))
  }

  render() {
    const {
      addedContainers,
      updateSortedColumn,
      sortedColumn,
      order,
      columns,
      toggleColumn,
      addContainer,
      addContainerStatuses,
      removeContainerFromAddedDiff,
      validValuesOf
    } = this.props

    const { selectedContainers, showColumnSelect } = this.state

    const allChecked = selectedContainers.length === addedContainers.length

    return (
      <div>
        <TableHeader
          numberOfSelectedItems={selectedContainers.length}
          selectedHeaderChildren={
            <ButtonContainer>
              <TableHeaderIconButton onClick={this._addSelectedContainers}>Add Selected</TableHeaderIconButton>
            </ButtonContainer>
          }
          headerChildren={
            <div>
              <button onClick={this._toggleColumnSelect}>
                <MenuHorizontal className="inline-block" width="1rem" height="1rem" />{' '}
              </button>
              {showColumnSelect && (
                <ColumnSelectContainer>
                  <ColumnSelect columns={columns} toggleColumn={toggleColumn} close={this._toggleColumnSelect} />
                </ColumnSelectContainer>
              )}
            </div>
          }
        />
        <FixedHeaderWidthTable className={'table '}>
          <thead>
            <tr>
              <th>
                <input type="checkbox" onChange={this._toggleAllContainers} checked={allChecked} />
              </th>
              {columns.map(
                column =>
                  column.visible && (
                    <SortableTh
                      key={column.fieldName}
                      name={column.fieldName}
                      onClick={updateSortedColumn}
                      order={sortedColumn === column.fieldName && order}
                    >
                      {column.label}
                    </SortableTh>
                  )
              )}
              <th />
            </tr>
          </thead>
          <tbody>
            {addedContainers.length > 0 ? (
              addedContainers.map(container => (
                <tr key={container.barcode}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={e => this._changeContainerSelected(e.target.checked, container)}
                      checked={this._isSelected(container.barcode)}
                      data-testid={'select-added-container:' + container.barcode}
                    />
                  </td>
                  <AddedRow
                    key={container.id}
                    columns={columns}
                    container={container}
                    addContainer={addContainer}
                    addContainerStatus={addContainerStatuses[container.barcode]}
                    removeContainerFromAddedDiff={removeContainerFromAddedDiff}
                    validValuesOf={validValuesOf}
                  />
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={2}>No added containers</td>
              </tr>
            )}
          </tbody>
        </FixedHeaderWidthTable>
      </div>
    )
  }
}

export const AddedList = observer(AddedListPure)
