const colors = {
  LIGHT_PRIMARY: '#3DBAD5',
  PRIMARY: 'rgb(0, 139, 199)',
  DARK_PRIMARY: 'rgb(0, 71, 132)',
  PRIMARY_TEXT: '#212121',
  SECONDARY_TEXT: '#757575',
  TERTIARY_TEXT: '#CCCCCC',
  GREY: '#F1F1F1',
  LIGHT_GREY: '#d2d2d2',
  LIGHTER_GREY: '#fcfcfc',
  LIGHT_GREEN: '#B0EAC8',
  GREEN: 'rgb(46, 204, 113)',
  DARK_GREEN: 'rgb(6, 164, 73)',
  LIGHT_RED: '#F4B8B3',
  RED: 'rgb(231, 76, 60)',
  DARK_RED: 'rgb(191, 36, 20)',
  LIGHT_YELLOW: '#F9E7AC',
  YELLOW: 'rgb(241, 196, 15)',
  DARK_YELLOW: 'rgb(201, 156, 0)',
  NAVBAR: 'rgb(0, 26, 56)'
}

export default colors
