import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { ContainersListRow } from './ContainersListRow'

import type { Container, ContainerColumn, Order } from '../../../dataTypes'
import { SortableTh } from './SortableTh'
import { TextField } from './TextField'
import { ColumnSelect } from './ColumnSelect'
import { ReactComponent as MenuHorizontal } from '@tomra/design-system/src/config/icons/menu-horizontal.svg'

const FixedHeaderWidthTable = styled.table`
  table-layout: auto;
  th {
    min-width: 70px;
    max-width: none;
  }

  td {
    max-width: none;
  }
`

const ColumnSelectTh = styled.th`
  position: relative;
  > button {
    padding: 0;
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    z-index 1;
  }
`

type Props = {
  containers: Container[]
  checkIfSelected: (containerToCheck: Container) => boolean
  selectContainer: Function
  toggleAllContainers: Function
  updateSortedColumn: Function
  sortedColumn: string
  order: Order
  updateColumnFilter: Function
  columnFilters: { [columnName: string]: string }
  columns: ContainerColumn[]
  toggleColumn: Function
  loadingContainers: boolean
}

type State = {
  showEditColumns: boolean
}

export class ContainersList extends React.Component<Props, State> {
  state: State = {
    showEditColumns: false
  }

  _toggleEditColumns = () => {
    this.setState(prevState => {
      return { showEditColumns: !prevState.showEditColumns }
    })
  }

  render() {
    const {
      containers,
      checkIfSelected,
      selectContainer,
      toggleAllContainers,
      updateSortedColumn,
      sortedColumn,
      order,
      updateColumnFilter,
      columnFilters,
      columns,
      toggleColumn,
      loadingContainers
    } = this.props

    const visibleColumns = columns.filter(c => c.visible)

    const empty = containers.length < 1

    return (
      <div>
        {loadingContainers && <div className="loadingSpinner centerAbsolute" />}
        <FixedHeaderWidthTable className={'table'}>
          <thead>
            <tr>
              <ColumnSelectTh>
                <button onClick={this._toggleEditColumns}>
                  <MenuHorizontal className="inline-block" width="1rem" height="1rem" />
                </button>
                {this.state.showEditColumns && (
                  <ColumnSelect columns={columns} toggleColumn={toggleColumn} close={this._toggleEditColumns} />
                )}
              </ColumnSelectTh>
              {visibleColumns.map(column => (
                <th key={column.fieldName}>
                  <TextField
                    placeholder="Search"
                    value={columnFilters[column.fieldName] || ''}
                    onChange={value => updateColumnFilter(value, column.fieldName)}
                  />
                </th>
              ))}
            </tr>

            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={() => toggleAllContainers()}
                  checked={containers.every(checkIfSelected)}
                />
              </th>

              {visibleColumns.map(column => {
                return (
                  <SortableTh
                    key={column.fieldName}
                    name={column.fieldName}
                    onClick={updateSortedColumn}
                    order={sortedColumn === column.fieldName && order}
                  >
                    <abbr title={column.fullTitle}>{column.label}</abbr>
                  </SortableTh>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {empty && (
              <tr>
                <td>&nbsp;</td>
                <td colSpan={visibleColumns.length}>
                  {loadingContainers
                    ? 'Containers are being loaded...'
                    : 'There are currently no containers registered.'}
                </td>
              </tr>
            )}
            {!empty &&
              containers.map(container => (
                <ContainersListRow
                  key={container.id}
                  container={container}
                  isSelected={checkIfSelected(container)}
                  selectContainer={selectContainer}
                  visibleColumns={visibleColumns}
                  outdated={loadingContainers}
                />
              ))}
          </tbody>
        </FixedHeaderWidthTable>
      </div>
    )
  }
}

export default observer(ContainersList)
