import React, { Component, RefObject } from 'react'
import styled from 'styled-components'

import { spacing } from '../../../styles'
import {
  BarcodeInput,
  CapacityInput,
  DiameterInput,
  DiameterOverrideInput,
  HeightInput,
  HeightOverrideInput,
  MaterialTypeInput,
  ProductNameInput,
  ShapeGroupInput,
  SortCodeInput,
  SortCodeReadOnlyInput,
  SourceReadonlyInput,
  ToleranceClassInput,
  WeightInput
} from './ContainerFormComponents'

import type { ContainerUserRegisteredFields as Fields } from '../../../dataTypes'

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 900px;
  > *:not(:last-child) {
    margin-right: ${spacing.md};
  }
`

type Props = Fields & {
  validValuesOf: (fieldName: string) => string[]
  prefilledSortCode: number
  isDummyBarcode: boolean
  setField: Function
  setNumericField: Function
}

export class AddContainerForm extends Component<Props> {
  _barcodeElement: RefObject<HTMLInputElement>
  constructor(props) {
    super(props)
    this._barcodeElement = React.createRef()
  }

  render() {
    const {
      barcode,
      productName,
      materialType,
      heightWithCapMm,
      heightOverride,
      largestDiameterMm,
      diameterOverride,
      weightEmptyGm,
      capacityMl,
      sortCode,
      toleranceClass,
      shapeGroup,
      prefilledSortCode,
      isDummyBarcode,
      setField,
      setNumericField,
      validValuesOf
    } = this.props

    // @ts-ignore
    return (
      <div>
        <InnerContainer>
          <BarcodeInput currentValue={barcode} setField={setField} innerRef={this._barcodeElement} size={40} required />
        </InnerContainer>

        <InnerContainer>
          <SourceReadonlyInput currentValue="TOMRA" />

          <ProductNameInput currentValue={productName} setField={setField} required />
        </InnerContainer>

        <InnerContainer>
          <MaterialTypeInput currentValue={materialType} setField={setField} validValuesOf={validValuesOf} />
        </InnerContainer>

        <InnerContainer>
          <HeightInput currentValue={heightWithCapMm} setField={setNumericField} required />
          <HeightOverrideInput currentValue={heightOverride} setField={setNumericField} />
        </InnerContainer>

        <InnerContainer>
          <WeightInput currentValue={weightEmptyGm} setField={setNumericField} required />
        </InnerContainer>

        <InnerContainer>
          <DiameterInput currentValue={largestDiameterMm} setField={setNumericField} required />
          <DiameterOverrideInput currentValue={diameterOverride} setField={setNumericField} />
        </InnerContainer>

        <InnerContainer>
          <CapacityInput currentValue={capacityMl} setField={setNumericField} required />
        </InnerContainer>

        <h3>Handling</h3>
        <InnerContainer>
          <ToleranceClassInput currentValue={toleranceClass} setField={setNumericField} />
          <ShapeGroupInput currentValue={shapeGroup} setField={setNumericField} />
          {isDummyBarcode && <SortCodeInput currentValue={sortCode} setField={setNumericField} />}
          {!isDummyBarcode && <SortCodeReadOnlyInput currentValue={prefilledSortCode} />}
        </InnerContainer>
      </div>
    )
  }
}
