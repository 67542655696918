import React, { RefObject } from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../../../styles'
import { TextField } from './TextField'

const Label = styled.label`
  display: block;
  > span {
    font-size: smaller;
    color: ${colors.SECONDARY_TEXT};
  }
  > input[type='checkbox'] {
    margin-right: ${spacing.sm};
  }
`

type BooleanInputProp = {
  currentValue: boolean
  setField: Function
  required?: boolean
}

type NumberInputProp = {
  currentValue: number | null
  setField: Function
  required?: boolean
}

type NumberReadOnlyInputProp = {
  currentValue: number | null
}

type StringInputProp = {
  currentValue: string
  setField: Function
  size?: number
  required?: boolean
}

type StringReadonlyInputProp = {
  currentValue: string
}

type StringSelectProp = {
  currentValue: string
  setField: Function
  validValuesOf: (string) => string[]
}

function getNullsafeValue(value: any) {
  return value !== undefined && value !== null ? value : ''
}

export function SourceReadonlyInput({ currentValue }: StringReadonlyInputProp) {
  return (
    <Label>
      <span>Source {currentValue}</span>
      <TextField readOnly={true} value={getNullsafeValue(currentValue)} />
    </Label>
  )
}

export function BarcodeInput({ currentValue, setField, ...restProps }: StringInputProp & { innerRef: RefObject<any> }) {
  return (
    <Label>
      <span>Barcode</span>
      <TextField
        maxLength={14}
        minLength={8}
        value={getNullsafeValue(currentValue)}
        onChange={value => setField('barcode', value)}
        {...restProps}
      />
    </Label>
  )
}

export function ProductNameInput({ currentValue, setField, ...restProps }: StringInputProp) {
  return (
    <Label>
      <span>Product Name</span>
      <TextField
        value={getNullsafeValue(currentValue)}
        onChange={value => setField('productName', value)}
        {...restProps}
      />
    </Label>
  )
}

export function SupplierNameInput({ currentValue, setField, ...restProps }: StringInputProp) {
  return (
    <Label>
      <span>Supplier Name</span>
      <TextField
        value={getNullsafeValue(currentValue)}
        onChange={value => setField('supplierName', value)}
        {...restProps}
      />
    </Label>
  )
}

export function ProductGroupInput({ currentValue, setField, validValuesOf, ...restProps }: StringSelectProp) {
  return (
    <Label>
      <span>Product Group</span>
      <select className="btn" value={currentValue || ''} onChange={e => setField('productGroup', e.target.value)}>
        {validValuesOf('productGroup').map(value => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </select>
    </Label>
  )
}

export function ProductGroupOtherInput({ currentValue, setField, ...restProps }: StringInputProp) {
  return (
    <Label>
      <span>Product Group (other)</span>
      <TextField
        value={getNullsafeValue(currentValue)}
        onChange={value => setField('productGroupOther', value)}
        {...restProps}
      />
    </Label>
  )
}

export function SanInput({ currentValue, setField, ...restProps }: StringInputProp) {
  return (
    <Label>
      <span>Supplier Arrangement Number</span>
      <TextField value={getNullsafeValue(currentValue)} onChange={value => setField('san', value)} {...restProps} />
    </Label>
  )
}

export function MaterialTypeInput({ currentValue, setField, validValuesOf, ...restProps }: StringSelectProp) {
  return (
    <Label>
      <span>Material Type</span>
      <select className="btn" value={currentValue || ''} onChange={e => setField('materialType', e.target.value)}>
        <option/>
        {validValuesOf('materialType').map(value => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </select>
    </Label>
  )
}

export function MaterialTypeOtherInput({ currentValue, setField, ...restProps }: StringInputProp) {
  return (
    <Label>
      <span>Material Type (other)</span>
      <TextField
        value={getNullsafeValue(currentValue)}
        onChange={value => setField('materialTypeOther', value)}
        {...restProps}
      />
    </Label>
  )
}

export function GlassColorInput({ currentValue, setField, validValuesOf, ...restProps }: StringSelectProp) {
  return (
    <Label>
      <span>Glass Colour</span>
      <select className="btn" value={currentValue || ''} onChange={e => setField('glassColour', e.target.value)}>
        {validValuesOf('glassColour').map(value => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </select>
    </Label>
  )
}

export function GlassColorOtherInput({ currentValue, setField, ...restProps }: StringInputProp) {
  return (
    <Label>
      <span>Glass Colour (other)</span>
      <TextField
        value={getNullsafeValue(currentValue)}
        onChange={value => setField('glassColourOther', value)}
        {...restProps}
      />
    </Label>
  )
}

export function PetColorInput({ currentValue, setField, validValuesOf, ...restProps }: StringSelectProp) {
  return (
    <Label>
      <span>PET Colour</span>
      <select className="btn" value={currentValue || ''} onChange={e => setField('petColour', e.target.value)}>
        {validValuesOf('petColour').map(value => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </select>
    </Label>
  )
}

export function HeightInput({ currentValue, setField, ...restProps }: NumberInputProp) {
  return (
    <Label>
      <span>Height With Cap (mm)</span>
      <TextField
        type="number"
        min="0"
        max="380"
        value={getNullsafeValue(currentValue)}
        onChange={value => setField('heightWithCapMm', value)}
        {...restProps}
      />
    </Label>
  )
}

export function HeightOverrideInput({ currentValue, setField, ...restProps }: NumberInputProp) {
  return (
    <Label>
      <span>Height Override</span>
      <TextField
        type="number"
        min="0"
        max="380"
        value={getNullsafeValue(currentValue)}
        onChange={value => setField('heightOverride', value)}
        {...restProps}
      />
    </Label>
  )
}

export function DiameterInput({ currentValue, setField, ...restProps }: NumberInputProp) {
  return (
    <Label>
      <span>Largest Diameter (mm)</span>
      <TextField
        type="number"
        min="0"
        max="180"
        value={getNullsafeValue(currentValue)}
        onChange={value => setField('largestDiameterMm', value)}
        {...restProps}
      />
    </Label>
  )
}

export function DiameterOverrideInput({ currentValue, setField, ...restProps }: NumberInputProp) {
  return (
    <Label>
      <span>Diameter Override</span>
      <TextField
        type="number"
        min="0"
        max="180"
        value={getNullsafeValue(currentValue)}
        onChange={value => setField('diameterOverride', value)}
        {...restProps}
      />
    </Label>
  )
}

export function WeightInput({ currentValue, setField, ...restProps }: NumberInputProp) {
  return (
    <Label>
      <span>Weight Empty (grams)</span>
      <TextField
        type="number"
        min="1"
        max="2000"
        value={getNullsafeValue(currentValue)}
        onChange={value => setField('weightEmptyGm', value)}
        {...restProps}
      />
    </Label>
  )
}

export function CapacityInput({ currentValue, setField, ...restProps }: NumberInputProp) {
  return (
    <Label>
      <span>Designed Capacity (ml)</span>
      <TextField
        type="number"
        min="150"
        max="3000"
        value={getNullsafeValue(currentValue)}
        onChange={value => setField('capacityMl', value)}
        {...restProps}
      />
    </Label>
  )
}

export function ToleranceClassInput({ currentValue, setField, ...restProps }: NumberInputProp) {
  return (
    <Label>
      <span>Tolerance Class</span>
      <TextField
        type="number"
        min="0"
        max="39"
        value={getNullsafeValue(currentValue)}
        onChange={value => setField('toleranceClass', value)}
        {...restProps}
      />
    </Label>
  )
}

export function NswLogoInput({ currentValue, setField }: StringInputProp) {
  return (
    <Label>
      <input type="checkbox" checked={!!currentValue} onChange={evt => setField('nswRefundLogo', evt.target.checked)} />
      <span>NSW Refund Logo</span>
    </Label>
  )
}

export function SantLogoInput({ currentValue, setField }: StringInputProp) {
  return (
    <Label>
      <input
        type="checkbox"
        checked={!!currentValue}
        onChange={evt => setField('santRefundLogo', evt.target.checked)}
      />
      <span>SA/NT Refund Logo</span>
    </Label>
  )
}

export function ShapeGroupInput({ currentValue, setField, ...restProps }: NumberInputProp) {
  return (
    <Label>
      <span>Shape Group</span>
      <TextField
        type="number"
        value={getNullsafeValue(currentValue)}
        onChange={value => setField('shapeGroup', value)}
        {...restProps}
      />
    </Label>
  )
}

export function SortCodeInput({ currentValue, setField, ...restProps }: NumberInputProp) {
  return (
    <Label>
      <span>Sort Code</span>
      <TextField
        type="number"
        min="0"
        max="99"
        value={getNullsafeValue(currentValue)}
        onChange={value => setField('sortCode', value)}
        {...restProps}
      />
    </Label>
  )
}

export function SortCodeReadOnlyInput({ currentValue, ...restProps }: NumberReadOnlyInputProp) {
  return (
    <Label>
      <span>
        Sort Code{' '}
        <abbr title="Sort Code is based on material type unless barcode is a dummy (starts with 000000)">(?)</abbr>
      </span>
      <TextField readOnly type="number" value={getNullsafeValue(currentValue)} {...restProps} />
    </Label>
  )
}

export function ActiveInput({ currentValue, setField, ...restProps }: BooleanInputProp) {
  return (
    <Label>
      <input
        type="checkbox"
        checked={currentValue}
        {...restProps}
        onChange={evt => setField('active', evt.target.checked)}
      />
      <span>Active barcode</span>
    </Label>
  )
}

export function RegistrationStatusInput({ currentValue, setField, validValuesOf, ...restProps }: StringSelectProp) {
  return (
    <Label>
      <span>Registration Status</span>
      <select className="btn" value={currentValue || ''} onChange={e => setField('registrationStatus', e.target.value)}>
        {validValuesOf('registrationStatus').map(value => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </select>
    </Label>
  )
}

export function NewProductInput({ currentValue, setField, ...restProps }: BooleanInputProp) {
  return (
    <Label>
      <input
        type="checkbox"
        checked={currentValue}
        onChange={evt => setField('newProduct', evt.target.checked)}
        {...restProps}
      />
      <span>New Product</span>
    </Label>
  )
}

export function LaunchDateInput({ currentValue, setField, ...restProps }: StringInputProp) {
  return (
    <Label>
      <span>New Product Launch Date</span>
      <TextField
        value={getNullsafeValue(currentValue)}
        onChange={value => setField('newProductLaunchDate', value)}
        {...restProps}
      />
    </Label>
  )
}
