import { useLocation, useNavigate, useParams } from 'react-router-dom'
import React from 'react'

export const withRouter = Component => {
  const ComponentWithRouterProp = props => {
    let location = useLocation()
    let navigate = useNavigate()
    let params = useParams()
    return <Component {...props} location={location} params={params} navigate={navigate} />
  }
  return ComponentWithRouterProp
}