import React from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from '@tomra/design-system/src/config/icons/close.svg'

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
`

function createStyles(overflow) {
  const modalStyles = {
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.75)',
      zIndex: 10000,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: overflow ? 'auto' : 'initial'
    },

    content: {
      position: 'static',
      border: 'none',
      backgroundColor: 'transparent'
    }
  }

  return modalStyles
}

type Props = {
  visible?: boolean
  children: any
  onClose: Function
  shouldCloseOnOverlayClick?: boolean
  overflow?: boolean
}

export const Dialog = (props: Props) => (
  <Modal
    isOpen={props.visible}
    onRequestClose={props.onClose}
    style={createStyles(props.overflow)}
    contentLabel="Dialog"
    shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
    ariaHideApp={false}
  >
    <div className="card" style={{ position: 'relative' }}>
      {props.shouldCloseOnOverlayClick && (
        <CloseButton className={'btn btn-icon'} onClick={() => props.onClose()}>
          <CloseIcon className="inline-block" width="1rem" height="1rem" />
        </CloseButton>
      )}
      {props.children}
    </div>
  </Modal>
)
