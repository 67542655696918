import React, { Component, ReactNode } from 'react'
import styled from 'styled-components'
import { ReactComponent as ArrowUpIcon } from '@tomra/design-system/src/config/icons/arrow-up.svg'
import { ReactComponent as ArrowDownIcon } from '@tomra/design-system/src/config/icons/arrow-down.svg'

const InnerContainer = styled.div`
  display: ${(props: { expanded: boolean }) => (props.expanded ? 'block' : 'none')};
`

const Title = styled.a`
  display: block;
`

type Props = {
  title: string
  initialExpanded?: boolean
  titleClassName?: string
  children?: ReactNode
}

type State = {
  expanded: boolean
}

export class Collapsible extends Component<Props, State> {
  state = {
    expanded: !!this.props.initialExpanded
  }

  _toggleExpanded = e => {
    e.preventDefault()
    this.setState(prevState => ({ expanded: !prevState.expanded }))
  }

  render() {
    const { expanded } = this.state
    const { title, children, titleClassName } = this.props

    return (
      <div>
        <Title className={titleClassName} href="#collapsible-content" onClick={this._toggleExpanded}>
          {expanded ? (
            <span className="pr-1">
              <ArrowUpIcon className="inline-block" width="1rem" height="1rem" />
            </span>
          ) : (
            <span className="pr-1">
              <ArrowDownIcon className="inline-block" width="1rem" height="1rem" />
            </span>
          )}
          {title}
        </Title>
        <InnerContainer id="collapsible-content" expanded={expanded}>
          {children}
        </InnerContainer>
      </div>
    )
  }
}
