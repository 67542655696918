import React from 'react'
import { ReactComponent as FileUploadIcon } from '@tomra/design-system/src/config/icons/upload.svg'

type Props = {
  source: 'EPA' | 'TOMRA'
  uploadCallback: Function
  loading: boolean
}

export class FileUpload extends React.Component<Props> {
  _fileUploadHandler = event => {
    const file = event.target.files[0]
    this.props.uploadCallback(file, this.props.source)
  }

  render() {
    return (
      <div className="relative">
        <button className="btn pl-1 pr-1 w-full h-3">
          {this.props.loading ? (
            <div className="loadingSpinner" />
          ) : (
            <FileUploadIcon className="inline-block" width="1rem" height="1rem" />
          )}
          <span> Import {this.props.source}-file</span>
        </button>
        <input
          className="w-full h-full absolute top-0 left-0 opacity-0"
          type="file"
          onChange={this._fileUploadHandler}
        />
      </div>
    )
  }
}
