import React from 'react'
import tomraArrow from '../images/tomra-arrow.png'
import { TomraMenu } from './TomraMenu'
import { ReactComponent as LogoutIcon } from '@tomra/design-system/src/config/icons/logout.svg'

type HeaderProps = {
  children?: Node
  doLogout: (any) => void
}

export const Header = ({ doLogout }: HeaderProps) => {
  return (
    <div className="navbar">
      <div className="flex">
        <img src={tomraArrow} height="36" width="36" alt="TOMRA logo" />
        <h4 className="ml-md my-auto">Barcode Manager</h4>
      </div>

      <div className="flex gap-md">
        <TomraMenu />

        <button onClick={doLogout}>
          <LogoutIcon className="inline-block" width="2rem" height="2rem" />
        </button>
      </div>
    </div>
  )
}
