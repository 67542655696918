import React from 'react'
import { Provider } from 'mobx-react'
import { configure } from 'mobx'

import { App } from './App'
import { authentication } from './lib/authentication'
import { AddContainerUiState, ContainersDataStore, ContainersUiState, DiffDataStore } from './stores'

import 'normalize.css'
import { logError } from './lib/logError'
import { createRoot } from 'react-dom/client'
import '@tomra/design-system/src/config/tailwind.config.css'
import { BrowserRouter } from 'react-router-dom'

configure({ enforceActions: 'always' })

const containersDataStore = new ContainersDataStore()
const containersUiState = new ContainersUiState(containersDataStore)
const diffDataStore = new DiffDataStore()
const addContainerUiState = new AddContainerUiState()

authentication.init().then(
  () => {
    const container = document.getElementById('root')
    if (container === null) {
      alert('Application failed to start!')
      throw new Error('Root element missing!')
    }
    const root = createRoot(container)
    root.render(
      <Provider
        containersDataStore={containersDataStore}
        containersUiState={containersUiState}
        diffDataStore={diffDataStore}
        addContainerUiState={addContainerUiState}
      >
        <BrowserRouter>
            <App doLogout={authentication.logout} />
        </BrowserRouter>
      </Provider>
    )
  },
  (err: any) => {
    logError(new Error('Auth init err:'), err)
    authentication.login()
  }
)
