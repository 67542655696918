// Shadows based on material design
const SHADOW_XSMALL = 'rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px'
const SHADOW_SMALL = 'rgba(0, 0, 0, 0.156863) 0px 3px 10px, rgba(0, 0, 0, 0.227451) 0px 3px 10px'
const SHADOW_MEDIUM = 'rgba(0, 0, 0, 0.188235) 0px 10px 30px, rgba(0, 0, 0, 0.227451) 0px 6px 10px'
const SHADOW_LARGE = 'rgba(0, 0, 0, 0.247059) 0px 14px 45px, rgba(0, 0, 0, 0.219608) 0px 10px 18px'
const SHADOW_XLARGE = 'rgba(0, 0, 0, 0.298039) 0px 19px 60px, rgba(0, 0, 0, 0.219608) 0px 15px 20px'

const INSET_SHADOW_XSMALL = 'inset rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px'

const shadows = {
  SHADOW_XSMALL,
  SHADOW_SMALL,
  SHADOW_MEDIUM,
  SHADOW_LARGE,
  SHADOW_XLARGE,
  INSET_SHADOW_XSMALL
}

export default shadows
