import React from 'react'
import styled from 'styled-components'
import { colors, radiuses, shadows, spacing } from '../../../styles'
import { ReactComponent as ArrowDownIcon } from '@tomra/design-system/src/config/icons/arrow-down.svg'

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
`

const Container = styled.div`
  position: relative;
  text-align: center;
  font-size: smaller;
  cursor: pointer;
  width: inherit;
  padding: ${spacing.sm} ${spacing.md};
  border-radius: ${radiuses.RADIUS_DEFAULT};
  transition: 200ms;
  :hover {
    background-color: ${colors.GREY};
  }
`

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  > svg {
    position: relative;
    left: 5px;
  }
`

const OptionsContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  box-shadow: ${shadows.SHADOW_MEDIUM};
  background-color: white;
  border-radius: ${radiuses.RADIUS_DEFAULT};
`

const SelectOption = styled.div`
  cursor: pointer;
  min-width: 100%;
  transition: 200ms;
  padding: ${spacing.sm} ${spacing.md};

  :not(:first-child) {
    border-top: 1px solid ${colors.GREY};
  }

  :hover {
    background-color: ${colors.GREY};
  }
`

type Props = {
  recordsPerPage: number
  recordsPerPageOptions: number[]
  onChange: Function
}

type State = {
  displayDropdown: boolean
}

export class RecordsPerPageSelect extends React.Component<Props, State> {
  state = {
    displayDropdown: false
  }

  _toggleDropdown = () => {
    this.setState(prevState => {
      return { displayDropdown: !prevState.displayDropdown }
    })
  }

  render() {
    const { recordsPerPage, recordsPerPageOptions, onChange } = this.props

    return (
      <OuterContainer>
        <small>Records per page:</small>
        <Container onClick={this._toggleDropdown}>
          <InnerContainer>
            {recordsPerPage}
            <ArrowDownIcon className="inline-block" width="1rem" height="1rem" />
          </InnerContainer>
          {this.state.displayDropdown && (
            <OptionsContainer>
              {recordsPerPageOptions.map(option => (
                <SelectOption key={option} onClick={() => onChange(option)}>
                  {option}
                </SelectOption>
              ))}
            </OptionsContainer>
          )}
        </Container>
      </OuterContainer>
    )
  }
}
