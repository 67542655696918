import React, { Component, ComponentClass } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { AddContainerForm } from './AddContainerForm'
import { ContainerFormFooter } from './ContainerFormFooter'
import { spacing } from '../../../styles'

import type { Container, RequestProgress } from '../../../dataTypes'
import type { AddContainerUiState } from '../../../stores'
import { Dialog } from './Dialog'

const dontCloseDialog = () => {}

const ScrollingContent = styled.div`
  padding: ${spacing.xl};
  overflow: auto;
  max-height: 90vh;

  h1 {
    margin: 0;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const FooterContainer = styled.div`
  padding-top: ${spacing.lg};
`

type Props = {
  addContainer: (container: Container) => Promise<void>
  addContainerStatus?: RequestProgress
  onClose: () => void
  visible: boolean
  validValuesOf: (fieldName: string) => string[]
}

type PropsPure = Props & { addContainerUiState: AddContainerUiState }

class AddContainerDialogPure extends Component<PropsPure> {
  _submitFields = evt => {
    // evt is not defined when running tests
    evt && evt.preventDefault()
    this.props.addContainer(this.props.addContainerUiState.parsedFields).then(this._resetAndClose, dontCloseDialog)
  }

  _resetAndClose = () => {
    this.props.addContainerUiState.resetFields()
    this.props.onClose()
  }

  render() {
    const { visible, addContainerStatus, validValuesOf } = this.props

    const { fields, shapeGroupWithDefault, prefilledSortCode, isDummyBarcode, setField, setNumericField } =
      this.props.addContainerUiState

    return (
      <Dialog visible={visible} onClose={this._resetAndClose} shouldCloseOnOverlayClick>
        <ScrollingContent>
          <h1>Add barcode</h1>

          <Form onSubmit={this._submitFields}>
            <AddContainerForm
              {...fields}
              shapeGroup={shapeGroupWithDefault}
              {...{ prefilledSortCode, isDummyBarcode, setField, setNumericField, validValuesOf }}
            />
            <FooterContainer>
              <ContainerFormFooter
                onCancel={this._resetAndClose}
                status={addContainerStatus}
                submitBtnText="Add Barcode"
              />
            </FooterContainer>
          </Form>
        </ScrollingContent>
      </Dialog>
    )
  }
}

export const AddContainerDialog = inject('addContainerUiState')(
  observer(AddContainerDialogPure as unknown as ComponentClass<Props>)
)
