import React from 'react'
import { Order } from '../../../dataTypes'

type Props = {
  key?: any
  onClick: Function
  order?: Order
  name: string
  children: any
}

type State = {}

export class SortableTh extends React.Component<Props, State> {
  _onClick = () => {
    const order = this.props.order
    order === 'asc' ? this.props.onClick('desc', this.props.name) : this.props.onClick('asc', this.props.name)
  }

  render() {
    return (
      <th onClick={this._onClick} className={!!this.props.order ? this.props.order : ''}>
        {this.props.children}
      </th>
    )
  }
}
