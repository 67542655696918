import React from 'react'
import { observer } from 'mobx-react'
import { RemovedRow } from './RemovedRow'
import type { Container, ContainerColumn, Order } from '../../../../dataTypes'
import styled from 'styled-components'
import { SortableTh } from '../../containers/SortableTh'
import { ColumnSelect } from '../../containers/ColumnSelect'
import { ReactComponent as MenuHorizontal } from '@tomra/design-system/src/config/icons/menu-horizontal.svg'

const FixedHeaderWidthTable = styled.table`
  table-layout: auto;
  th {
    min-width: 70px;
    max-width: none;
  }

  td {
    max-width: none;
  }
`

const ColumnSelectTh = styled('th')`
  position: relative;
  > button {
    padding: 0;
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    z-index 1;
  }
`

export type Props = {
  containers: Container[]
  updateSortedColumn: Function
  sortedColumn?: string
  order?: Order
  columns: ContainerColumn[]
  toggleColumn: Function
  removeContainer: Function
  editContainer: Function
  removeContainerFromRemovedDiff: Function
}

type State = {
  showEditColumns: boolean
}

class RemovedListPure extends React.Component<Props, State> {
  state = {
    showEditColumns: false
  }

  _toggleEditColumns = () => {
    this.setState(prevState => {
      return { showEditColumns: !prevState.showEditColumns }
    })
  }

  render() {
    const {
      containers,
      updateSortedColumn,
      sortedColumn,
      order,
      columns,
      toggleColumn,
      removeContainer,
      editContainer,
      removeContainerFromRemovedDiff
    } = this.props

    return (
      <FixedHeaderWidthTable className={'table'}>
        <thead>
          <tr>
            <ColumnSelectTh>
              <button onClick={this._toggleEditColumns}>
                <MenuHorizontal className="inline-block" width="1rem" height="1rem" />
              </button>
              {this.state.showEditColumns && (
                <ColumnSelect columns={columns} toggleColumn={toggleColumn} close={this._toggleEditColumns} />
              )}
            </ColumnSelectTh>
            {columns.map(
              column =>
                column.visible && (
                  <SortableTh
                    key={column.fieldName}
                    name={column.fieldName}
                    onClick={updateSortedColumn}
                    order={sortedColumn === column.fieldName && order}
                  >
                    {column.label}
                  </SortableTh>
                )
            )}
            <th />
          </tr>
        </thead>
        <tbody>
          {containers.length > 0 ? (
            containers.map(container => (
              <RemovedRow
                key={container.id}
                columns={columns}
                container={container}
                removeContainer={removeContainer}
                editContainer={editContainer}
                removeContainerFromRemovedDiff={removeContainerFromRemovedDiff}
              />
            ))
          ) : (
            <tr>
              <td colSpan={2}>No removed containers were found.</td>
            </tr>
          )}
        </tbody>
      </FixedHeaderWidthTable>
    )
  }
}

export const RemovedList = observer(RemovedListPure)
