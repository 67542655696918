import React from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../../../styles'

import type { ErrorCode, RequestProgress } from '../../../dataTypes'

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > * {
    margin-left: ${spacing.md};
  }
`

const ErrorText = styled.span`
  color: ${colors.RED};
`

const errorFeedbackByStatus: { [key in ErrorCode]: string } = {
  'not-allowed-error': 'You are not allowed to perform these changes.',
  'unknown-error': 'An unknown technical error occured, try again or contact support.'
}

type ContainerFormFooterProps = {
  onCancel: () => void
  status?: RequestProgress
  submitBtnText: string
}

export const ContainerFormFooter = ({ onCancel, submitBtnText, status }: ContainerFormFooterProps) => {
  const isPersisting = status?.status === 'loading'
  const errorFeedbacks: string[] = status?.errors
    ? status.errors.map(e => e.userMessage || errorFeedbackByStatus[e.code])
    : []

  return (
    <Footer>
      {errorFeedbacks.map((errorMessage, i) => (
        <ErrorText key={i}>{errorMessage}</ErrorText>
      ))}
      <button className="btn" onClick={onCancel}>
        Cancel
      </button>
      <button className="btn btn-primary-dark border-[#1fc0da] flex items-center" type="submit" disabled={isPersisting}>
        {isPersisting && <div className="loadingSpinner text-sm" />} <span>{submitBtnText}</span>
      </button>
    </Footer>
  )
}
