import React from 'react'
import styled from 'styled-components'
import { colors, radiuses, shadows, spacing } from '../../../styles'
import { ReactComponent as CloseIcon } from '@tomra/design-system/src/config/icons/close.svg'

const Container = styled.div`
  position: relative;
  width: 300px;
  max-height 400px;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: ${shadows.SHADOW_MEDIUM};
  border-radius: ${radiuses.RADIUS_DEFAULT};
  overflow: scroll;
  > h3 {
    margin-left: ${spacing.md};
  }
  > button {
    position: absolute;
    top: 0;
    right: 0;
  }
`

export const Option = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacing.md};
  cursor: pointer;
  transition: 200ms;
  color: ${colors.SECONDARY_TEXT};
  font-size: smaller;
  font-weight: bold;
  text-transform: uppercase;
  border-top: 1px solid ${colors.GREY};
  > input {
    margin-right: ${spacing.md};
  }
  :hover {
    background-color: ${colors.GREY};
  }
`

type Column = {
  fieldName: string
  label: string
  mutable: boolean
  visible: boolean
}

type Props = {
  columns: Array<Column>
  toggleColumn: Function
  close: Function
}

export const ColumnSelect = ({ columns, toggleColumn, close }: Props) => (
  <Container>
    <div className="p-1"> <h3>Edit Columns</h3></div>
    {columns.map(column => (
      <Option key={column.fieldName} onClick={() => toggleColumn(column.fieldName)}>
        <input
          type={'checkbox'}
          onChange={() => toggleColumn(column.fieldName)}
          onClick={e => e.stopPropagation()}
          checked={column.visible}
        />
        {column.label}
      </Option>
    ))}
    <button className="btn btn-icon" onClick={() => close()}>
      <CloseIcon className="inline-block" width="1rem" height="1rem" />
    </button>
  </Container>
)
