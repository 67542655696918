import React, { Fragment } from 'react'
import styled from 'styled-components'
import { AddedRowTd } from './AddedRowTd'
import { spacing } from '../../../../styles'

import type { Container, ContainerColumn, RequestProgress } from '../../../../dataTypes'

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  > *:nth-child(even) {
    margin: 0px ${spacing.sm};
  }
`

type Props = {
  container: Container
  columns: ContainerColumn[]
  addContainer: (container: Container) => Promise<void>
  addContainerStatus?: RequestProgress
  removeContainerFromAddedDiff: (string) => void
  validValuesOf: Function
}

type State = {
  correctedContainer: Container
}

export class AddedRow extends React.Component<Props, State> {
  state = {
    correctedContainer: { ...this.props.container }
  }

  _onChange = (fieldName, value) => {
    const correctedContainer = this.state.correctedContainer
    correctedContainer[fieldName] = value

    this.setState({ correctedContainer })
  }

  _addContainer = async () => {
    await this.props.addContainer(this.state.correctedContainer)
    this._removeContainerFromAddedDiff()
  }

  _removeContainerFromAddedDiff = () => {
    this.props.removeContainerFromAddedDiff(this.state.correctedContainer.id)
  }

  render() {
    const { addContainerStatus, columns, validValuesOf } = this.props
    const { correctedContainer } = this.state

    const error = addContainerStatus?.status === 'error'
    const loading = addContainerStatus?.status === 'loading'

    return (
      <Fragment>
        {columns.map(
          column =>
            column.visible && (
              <AddedRowTd
                key={column.fieldName}
                column={column}
                value={correctedContainer[column.fieldName]}
                onChange={this._onChange}
                validValuesOf={validValuesOf}
              />
            )
        )}

        <td>
          <ActionContainer>
            {error && <div className={'alert alert-danger'}>Update failed.</div>}
            {loading && <div className="loadingSpinner text-sm" />}
            {!loading && (
              <Fragment>
                <button className="btn btn-primary-dark" onClick={this._addContainer}>
                  Add
                </button>
                <button className="btn btn-primary-danger" onClick={this._removeContainerFromAddedDiff}>
                  Decline
                </button>
              </Fragment>
            )}
          </ActionContainer>
        </td>
      </Fragment>
    )
  }
}
