import React, { Component } from 'react'
import styled from 'styled-components'
import { EditContainerForm } from './EditContainerForm'
import { ContainerFormFooter } from './ContainerFormFooter'
import { spacing } from '../../../styles'

import type { Container, RequestProgress } from '../../../dataTypes'
import { Dialog } from './Dialog'

const doNothing = () => {}

const ScrollingContent = styled.div`
  overflow: auto;
  max-height: 90vh;
`

const FooterContainer = styled.div`
  padding-right: ${spacing.lg};
  padding-bottom: ${spacing.lg};
`

type Props = {
  editContainer: (container: Container) => Promise<void>
  resetEditContainerStatus: () => void
  resetSelection: Function
  editingContainer: Container
  editContainerStatus?: RequestProgress
  onClose: () => void
  validValuesOf: (fieldName: string) => string[]
}

export class EditContainerDialog extends Component<Props> {
  _resetProgressThenClose = () => {
    this.props.resetEditContainerStatus()
    !this.props.editingContainer.active && this.props.resetSelection()
    this.props.onClose()
  }

  _editContainerThenClose = containerFields => {
    this.props.editContainer(containerFields).then(this._resetProgressThenClose, doNothing)
  }

  render() {
    const { editingContainer, editContainerStatus, validValuesOf } = this.props

    return (
      <Dialog visible={true} onClose={this._resetProgressThenClose} shouldCloseOnOverlayClick>
        <ScrollingContent>
          <EditContainerForm
            initialFieldValues={editingContainer}
            onSubmit={this._editContainerThenClose}
            validValuesOf={validValuesOf}
          >
            <FooterContainer>
              <ContainerFormFooter
                onCancel={this._resetProgressThenClose}
                status={editContainerStatus}
                submitBtnText="Save changes"
              />
            </FooterContainer>
          </EditContainerForm>
        </ScrollingContent>
      </Dialog>
    )
  }
}
