import React from 'react'

export type Step = 'added' | 'changed' | 'removed' | 'invalid'

type Props = {
  updateCurrentStep: Function
  currentStep: Step
  numOfAdded: number
  numOfChanged: number
  numOfRemoved: number
  numOfInvalid: number
}

export const DiffTabs = (props: Props) => {
  const buttonClassNames = (step: Step) => {
    const defaultClassNames = 'font-bold p-1 text-base'
    return props.currentStep === step ? 'tab active'.concat(' ', defaultClassNames) : ''.concat(' ', defaultClassNames)
  }

  return (
    <div className={'tabs'}>
      <button onClick={() => props.updateCurrentStep('added')} className={buttonClassNames('added')}>
        Added ({props.numOfAdded})
      </button>
      <button onClick={() => props.updateCurrentStep('changed')} className={buttonClassNames('changed')}>
        Changed ({props.numOfChanged})
      </button>
      <button onClick={() => props.updateCurrentStep('removed')} className={buttonClassNames('removed')}>
        Removed ({props.numOfRemoved})
      </button>
      <button onClick={() => props.updateCurrentStep('invalid')} className={buttonClassNames('invalid')}>
        Invalid ({props.numOfInvalid})
      </button>
    </div>
  )
}

export default DiffTabs
