import React, { CSSProperties } from 'react'
import { defaultToOutput } from '../../../../stores/containerColumnMap'
import { colors, spacing } from '../../../../styles'
import type { ContainerColumn } from '../../../../dataTypes'
import styled from 'styled-components'
import { TableTextField } from '../../containers/TableTextField'
import { TableSelect } from '../../containers/TableSelect'

const ChangedTd = styled('td')`
  border-bottom: 2px solid ${colors.PRIMARY};
`
const SelectOption = styled.div`
  cursor: pointer;
  min-width: 100%;
  transition: 200ms;
  padding: ${spacing.sm} ${spacing.md};

  :not(:first-child) {
    border-top: 1px solid ${colors.GREY};
  }

  :hover {
    background-color: ${colors.GREY};
  }
`

const ChangedSelectOption = styled(SelectOption)`
  display: flex;
  align-items: center;
  flex-direction: column;
  > small {
    color: ${colors.SECONDARY_TEXT};
  }
`

type Props = {
  column: ContainerColumn
  oldValue: any
  newValue: any
  correctedValue: any
  onChange: Function
  validValuesOf: Function
}

type State = {
  editEnabled: boolean
}

export class ChangedRowTd extends React.Component<Props, State> {
  state = {
    editEnabled: this.props.oldValue === this.props.newValue
  }

  _enableEdit = () => {
    this.setState({ editEnabled: true })
  }

  render() {
    const { column, oldValue, newValue, correctedValue, onChange, validValuesOf } = this.props
    const { mutable, fieldName } = column
    const validValuesOfField = validValuesOf(fieldName) || []

    const toOutput = column.toOutput || defaultToOutput
    const formattedCorrectedValue = toOutput(correctedValue)
    const changedStyle: CSSProperties = correctedValue !== oldValue ? { fontWeight: 'bold' } : {}

    if (!mutable) {
      return <td>{formattedCorrectedValue}</td>
    }

    if (this.state.editEnabled) {
      return (
        <td style={changedStyle}>
          {validValuesOfField.length !== 0 ? (
            <TableSelect selectedValue={formattedCorrectedValue}>
              {validValuesOfField.map(value => (
                <SelectOption key={value} onClick={() => onChange(value)}>
                  {toOutput(value)}
                </SelectOption>
              ))}
            </TableSelect>
          ) : (
            <TableTextField value={formattedCorrectedValue} onChange={onChange} />
          )}
        </td>
      )
    }

    return (
      <ChangedTd style={changedStyle}>
        <TableSelect selectedValue={formattedCorrectedValue}>
          <ChangedSelectOption onClick={() => onChange(newValue)}>
            <small>New value:</small>
            <span>{toOutput(newValue)}</span>
          </ChangedSelectOption>

          <ChangedSelectOption onClick={() => onChange(oldValue)}>
            <small>Master value:</small>
            <span>{toOutput(oldValue)}</span>
          </ChangedSelectOption>

          <SelectOption onClick={this._enableEdit}>
            <b>Edit</b>
          </SelectOption>
        </TableSelect>
      </ChangedTd>
    )
  }
}
