import React, { ComponentClass, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { inject } from 'mobx-react'

import { ListContainer } from './components/assortment/ListContainer'
import { DiffContainer } from './components/assortment/DiffContainer'
import { Header } from './components/Header'

import type { ContainersDataStore } from './stores'
import { CLUSTER_NAME, VERSION } from './lib/environment'

type Props = {
  doLogout: (any) => void
}

type PropsPure = Props & {
  containersDataStore: ContainersDataStore
}

const AppPure = (props: PropsPure) => {
  useEffect(() => {
    props.containersDataStore.fetchValidValues()
  }, [props.containersDataStore])

  return (
    <div>
      <Header doLogout={props.doLogout} />

      <div className="m-md">
        <Routes>
          <Route path="/diff" element={<DiffContainer />} />
          <Route path="/:action?/:id?" element={<ListContainer />} />
          <Route path="/" element={<Navigate to="/edit" />} />
        </Routes>
        <div>
          {VERSION} | {CLUSTER_NAME}
        </div>
      </div>
    </div>
  )
}

export const App = inject('containersDataStore')(AppPure as unknown as ComponentClass<Props>)
