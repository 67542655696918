import React, { ReactNode } from 'react'

import { API_HOST } from '../../lib/environment'
import { authentication } from '../../lib/authentication'

export function ExportButton(props: { clearingHouse: string; children?: ReactNode }) {
  return (
    <form method="post" action={`${API_HOST}assortment/${props.clearingHouse}/export/csv`}>
      <input type="hidden" name="jwt" value={authentication.getToken()} />
      <button className="btn min-h-3 pl-1 pr-1" type="submit">
        {props.children}
      </button>
    </form>
  )
}
