import { action, extendObservable, runInAction } from 'mobx'
import orderBy from 'lodash/orderBy'

import * as containersService from '../services/ContainersService'

import type { ChangedContainer, Container, Order } from '../dataTypes'
import { ValidationMessage } from '../dataTypes'

export class DiffDataStore {
  addedDiff!: Container[]
  changedDiff!: ChangedContainer[]
  removedDiff!: Container[]
  valid!: boolean
  validationMessages!: ValidationMessage[]

  uploadAssortmentFile!: Function

  addedSort!: (Order, string) => void
  changedSort!: (Order, string) => void
  removedSort!: (Order, string) => void

  removeContainerFromAddedDiff!: (string) => void
  removeContainerFromChangedDiff!: (string) => void
  removeContainerFromRemovedDiff!: (string) => void

  constructor() {
    extendObservable(this, {
      addedDiff: [],
      changedDiff: [],
      removedDiff: [],
      valid: true,
      validationMessages: [],

      uploadAssortmentFile: action(async (file: File, source: 'EPA' | 'TOMRA') => {
        const {
          added,
          updated,
          removed,
          validationStatus: { valid, validationMessages }
        } = await containersService.uploadAssortmentFile(file, source)

        runInAction(() => {
          this.addedDiff = added
          this.changedDiff = updated
          this.removedDiff = removed
          this.valid = valid
          this.validationMessages = validationMessages
        })
      }),

      addedSort: action((order: Order, columnName: string) => {
        this.addedDiff = orderBy(this.addedDiff, columnName, order || undefined)
      }),

      removeContainerFromAddedDiff: action(id => {
        this.addedDiff = this.addedDiff.filter(container => container.id !== id)
      }),

      changedSort: action((order: Order, columnName: string) => {
        this.changedDiff = orderBy(this.changedDiff, 'oldValue.' + columnName, order || undefined)
      }),

      removeContainerFromChangedDiff: action(id => {
        this.changedDiff = this.changedDiff.filter(container => container.newValue.id !== id)
      }),

      removedSort: action((order: Order, columnName: string) => {
        this.removedDiff = orderBy(this.removedDiff, columnName, order || undefined)
      }),

      removeContainerFromRemovedDiff: action(id => {
        this.removedDiff = this.removedDiff.filter(container => container.id !== id)
      })
    })
  }
}
