import React from 'react'
import styled from 'styled-components'

import { defaultToOutput } from '../../../../stores/containerColumnMap'
import { spacing } from '../../../../styles'

import type { Container, ContainerColumn } from '../../../../dataTypes'

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  > *:nth-child(even) {
    margin: 0px ${spacing.sm};
  }
`

type Props = {
  container: Container
  columns: ContainerColumn[]
  removeContainer: Function
  editContainer: Function
  removeContainerFromRemovedDiff: Function
}

type State = {
  loading: boolean
  error?: Error
}

function getOutput(container, column) {
  const toOutput = column.toOutput || defaultToOutput
  return toOutput(container[column.fieldName])
}

export class RemovedRow extends React.Component<Props, State> {
  state = {
    loading: false,
    error: undefined
  }

  _removeContainer = async () => {
    try {
      this.setState({ loading: true, error: undefined })
      await this.props.removeContainer(this.props.container.id)
      this.props.removeContainerFromRemovedDiff(this.props.container.id)
      this.setState({ loading: false })
    } catch (error: any) {
      this.setState({ loading: false, error })
    }
  }

  _keepContainer = async () => {
    try {
      this.setState({ loading: true, error: undefined })
      await this.props.editContainer({ ...this.props.container, source: 'TOMRA' })
      this.props.removeContainerFromRemovedDiff(this.props.container.id)
      this.setState({ loading: false })
    } catch (error: any) {
      this.setState({ loading: false, error })
    }
  }

  render() {
    const { container, columns } = this.props
    const { loading, error } = this.state

    return (
      <tr>
        <td />
        {columns.map(column => column.visible && <td key={column.fieldName}>{getOutput(container, column)}</td>)}
        <td>
          <ActionContainer>
            {error && <div className={'alert alert-danger'}>Update failed.</div>}
            <button className="btn btn-primary-dark" onClick={this._keepContainer}>
              Keep
            </button>
            <button className="btn btn-primary-danger" onClick={this._removeContainer}>
              {loading ? <div className="loadingSpinner text-sm" /> : 'Remove'}
            </button>
          </ActionContainer>
        </td>
      </tr>
    )
  }
}
