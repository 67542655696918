import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { RemovedList } from './RemovedList'

import type { ContainersDataStore, ContainersUiState, DiffDataStore } from '../../../../stores'
import type { Order } from '../../../../dataTypes'
import { IReactComponent } from 'mobx-react/dist/types/IReactComponent'

type Props = {}

type PropsPure = Props & {
  diffDataStore: DiffDataStore
  containersDataStore: ContainersDataStore
  containersUiState: ContainersUiState
}

type State = {
  sortedColumn: string
  order?: Order
}

export class RemovedListContainerPure extends Component<PropsPure, State> {
  state: Readonly<State> = {
    sortedColumn: 'productName',
    order: 'desc'
  }

  _updateSortedColumn = (order, columnName) => {
    this.props.diffDataStore.removedSort(order, columnName)
    this.setState({ order: order, sortedColumn: columnName })
  }

  render() {
    const { removedDiff, removeContainerFromRemovedDiff } = this.props.diffDataStore
    const { columns, toggleColumn } = this.props.containersUiState
    const { removeContainer, editContainer } = this.props.containersDataStore
    const { sortedColumn, order } = this.state

    return (
      <RemovedList
        containers={removedDiff}
        updateSortedColumn={this._updateSortedColumn}
        sortedColumn={sortedColumn}
        order={order}
        columns={columns}
        toggleColumn={toggleColumn}
        removeContainer={removeContainer}
        editContainer={editContainer}
        removeContainerFromRemovedDiff={removeContainerFromRemovedDiff}
      />
    )
  }
}

export const RemovedListContainer = inject(
  'containersDataStore',
  'diffDataStore',
  'containersUiState'
)(observer(RemovedListContainerPure)) as unknown as IReactComponent<Props>
