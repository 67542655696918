import React from 'react'
import styled from 'styled-components'
import { colors, radiuses, shadows, spacing } from '../../../styles'
import { ReactComponent as ArrowDownIcon } from '@tomra/design-system/src/config/icons/arrow-down.svg'

const Container = styled.div`
  position: relative;
  text-align: center;
  cursor: pointer;
  width: inherit;
  padding: ${spacing.sm} ${spacing.md};
  border-radius: ${radiuses.RADIUS_DEFAULT};
  transition: 200ms;
  :hover {
    background-color: ${colors.GREY};
  }
`

const OptionsContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  box-shadow: ${shadows.SHADOW_MEDIUM};
  background-color: white;
  border-radius: ${radiuses.RADIUS_DEFAULT};
`

const Placeholder = styled.span`
  color: ${colors.SECONDARY_TEXT};
  font-size: smaller;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  > svg {
    position: relative;
    left: 5px;
  }
`

type Props = {
  selectedValue: any
  children: any
}

type State = {
  displayDropdown: boolean
}

export class TableSelect extends React.Component<Props, State> {
  state = {
    displayDropdown: false
  }

  _toggleDropdown = () => {
    this.setState(prevState => {
      return { displayDropdown: !prevState.displayDropdown }
    })
  }

  render() {
    const { selectedValue, children } = this.props

    return (
      <Container onClick={this._toggleDropdown}>
        {selectedValue ? (
          String(selectedValue)
        ) : (
          <Placeholder>
            Select a value <ArrowDownIcon className="inline-block" width="1rem" height="1rem" />
          </Placeholder>
        )}
        {this.state.displayDropdown && <OptionsContainer>{children}</OptionsContainer>}
      </Container>
    )
  }
}
