import React, { ReactElement, RefObject } from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../../../styles'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Input = styled.input<{ valid: boolean; touched: boolean }>`
  background-color: transparent;
  padding: ${spacing.sm} 0px;
  border-radius: 0;
  transition: 200ms;
  opacity: 0.7;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: ${props =>
    !props.valid && props.touched ? `1px solid ${colors.RED}` : `1px solid ${colors.SECONDARY_TEXT}`};
  width: 100%;

  :focus {
    outline: 0;
    opacity: 1;
  }
`

export const ErrorMessage = styled.p`
  margin: ${spacing.xs} 0px;
  color: ${colors.RED};
  text-align: left;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`

type Props = {
  onChange: Function
  value?: string
  placeholder?: string
  valid: boolean
  pattern?: string | RegExp
  errorMsg?: string
  children?: ReactElement
  required?: boolean
  innerRef?: RefObject<any>
  maxLength?: number
  minLength?: number
  min?: string
  max?: string
  type?: string
  readOnly?: boolean
}

type State = {
  touched: boolean
}

export class TextField extends React.Component<Props, State> {
  static defaultProps = {
    valid: true,
    onChange: () => {}
  }

  state = {
    touched: false
  }

  _onChange = evt => {
    const { onChange, pattern, required } = this.props
    const newValue = evt.target.value

    if (pattern) {
      const isValid = new RegExp(pattern).exec(newValue)

      onChange(newValue, !!isValid) // cast isValid to true bool
    } else if (required && newValue === '') {
      onChange(newValue, false)
    } else {
      onChange(newValue, true)
    }
  }

  _onBlur = evt => {
    this.setState({ touched: true })
    this._onChange(evt)
  }

  render() {
    const { children, valid, errorMsg } = this.props
    const defaultMsg = this.props.required ? 'This field is required' : 'Invalid input'

    return (
      <Container>
        <Input
          placeholder={this.props.placeholder}
          type={this.props.type}
          min={this.props.min}
          max={this.props.max}
          maxLength={this.props.maxLength}
          minLength={this.props.minLength}
          required={this.props.required}
          innerRef={this.props.innerRef}
          readOnly={this.props.readOnly}
          onChange={this._onChange}
          onBlur={this._onBlur}
          valid={valid}
          touched={this.state.touched}
          value={this.props.value}
        />

        {children}

        {!this.props.valid && this.state.touched ? <ErrorMessage>{errorMsg || defaultMsg}</ErrorMessage> : null}
      </Container>
    )
  }
}
