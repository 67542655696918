import React from 'react'

type Props = {
  numberOfSelectedItems: number
  selectedHeaderChildren?: any
  headerChildren?: any
}

export const TableHeader = (props: Props) => {
  const numberOfSelectedItems = props.numberOfSelectedItems
  const isSelected = numberOfSelectedItems !== 0

  if (isSelected) {
    return (
      <div className="flex justify-between bg-blue text-white p-lg py-md">
        <span>{numberOfSelectedItems} items selected</span>
        <div>{props.selectedHeaderChildren}</div>
      </div>
    )
  }

  return <div style={{ display: 'flex' }}>{props.headerChildren}</div>
}
