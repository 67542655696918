import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useNavigate, useParams } from 'react-router-dom'

import { createPortal } from 'react-dom'
import { Link } from 'react-router-dom'

import { CLEARING_HOUSES } from '../../lib/environment'
import ContainersList from './containers/ContainersList'
import { AddContainerDialog } from './containers/AddContainerDialog'
import { EditContainerDialog } from './containers/EditContainerDialog'
import { FileUpload } from './FileUpload'
import { ExportButton } from './ExportButton'

import type { ContainersDataStore, ContainersUiState, DiffDataStore } from '../../stores'
import type { ErrorCode } from '../../dataTypes'
import { TableFooter } from './containers/TableFooter'
import { TableHeader } from './containers/TableHeader'
import { ConfirmActionDialog } from './containers/ConfirmActionDialog'
import { ReactComponent as PlusIcon } from '@tomra/design-system/src/config/icons/plus.svg'
import { ReactComponent as TrashIcon } from '@tomra/design-system/src/config/icons/trash.svg'
import { ReactComponent as EditIcon } from '@tomra/design-system/src/config/icons/edit.svg'
import { ReactComponent as ExportIcon } from '@tomra/design-system/src/config/icons/export.svg'
import { withRouter } from '../../lib/withRouter'

const errorFeedbackByStatus: { [key in ErrorCode]: string } = {
  'not-allowed-error': 'You are not allowed to perform these changes.',
  'unknown-error': 'An unknown technical error occured, try again or contact support.'
}

type Props = {
  containersDataStore: ContainersDataStore
  diffDataStore: DiffDataStore
  containersUiState: ContainersUiState
}

function getShortName(clearingHouse) {
  switch (clearingHouse.toLowerCase()) {
    case 'exchangeforchange':
      return 'efc'
    default:
      return clearingHouse.toLowerCase()
  }
}

export const ListContainerPure = (props: Props) => {
  const params = useParams()
  const navigate = useNavigate()

  const [uploadAssortmentFileStatus, setUploadAssortmentFileStatus] = useState<
    'loadingEPA' | 'loadingTOMRA' | 'failed' | ''
  >('')
  const [addingContainer, setAddingContainer] = useState('')
  const [showConfirmActionDialog, setShowConfirmActionDialog] = useState(false)

  useEffect(() => {
    props.containersUiState.fetchContainersWithCurrentSettings()
    const containerId = params.id
    if (containerId) {
      props.containersDataStore.fetchContainerToEdit(containerId)
    }
  }, [params.id, props.containersDataStore, props.containersUiState])

  const loadContainer = () => {
    props.containersDataStore.fetchContainerToEdit(props.containersUiState.selectedContainers[0].id)
  }

  const closeDialog = () => {
    props.containersDataStore.resetAddContainerStatus(addingContainer)
    setAddingContainer('')
    navigate('/')
  }

  const openAddContainerDialog = () => {
    navigate('/add')
  }

  const closeConfirmActionDialog = () => {
    setShowConfirmActionDialog(false)
  }

  const makeConfirmActionDialogMessage = () => {
    const numContainersToBeDeleted = props.containersUiState.selectedContainers.length
    return `Do you want do delete ${numContainersToBeDeleted} container${numContainersToBeDeleted > 1 ? 's' : ''} ?`
  }

  const makeConfirmActionDialogHeader = () => {
    const numContainersToBeDeleted = props.containersUiState.selectedContainers.length
    return `Delete container${numContainersToBeDeleted > 1 ? 's' : ''}`
  }

  const addContainer = async containerFields => {
    setAddingContainer(containerFields.barcode)
    await props.containersDataStore.addContainer(containerFields)
    props.containersUiState.fetchContainersWithCurrentSettings()
  }

  const uploadAssortmentFile = async (file, source: 'EPA' | 'TOMRA') => {
    setUploadAssortmentFileStatus(source === 'EPA' ? 'loadingEPA' : source === 'TOMRA' ? 'loadingTOMRA' : 'failed')

    try {
      await props.diffDataStore.uploadAssortmentFile(file, source)
      setUploadAssortmentFileStatus('')
      navigate('/diff')
    } catch (error) {
      setUploadAssortmentFileStatus('failed')
    }
  }

  const _showConfirmActionDialog = () => {
    setShowConfirmActionDialog(true)
  }

  const removeSelectedContainers = async () => {
    const selectedContainers = props.containersUiState.selectedContainers
    await props.containersDataStore.removeContainers(selectedContainers.map(container => container.id))
    props.containersUiState.resetSelection()
    props.containersUiState.fetchContainersWithCurrentSettings()
    setShowConfirmActionDialog(false)
  }

  const {
    loadingContainersStatus,
    containers,
    totalCount,
    addContainerStatuses,
    editContainer,
    resetEditContainerStatus,
    editingContainer,
    editContainerStatus,
    removeContainerStatus,
    validValuesOf
  } = props.containersDataStore

  const addContainerStatus = addingContainer ? addContainerStatuses[addingContainer] : undefined

  const removeContainerErrorFeedback =
    removeContainerStatus && removeContainerStatus.errors && errorFeedbackByStatus[removeContainerStatus.errors[0].code]

  const {
    selectedContainers,
    checkIfSelected,
    selectContainer,
    toggleAllContainers,
    updateSortedColumn,
    sortedColumn,
    order,
    currentPage,
    numberOfPages,
    changePage,
    updateColumnFilter,
    columnFilters,
    columns,
    toggleColumn,
    recordsPerPage,
    updateRecordsPerPage,
    statusFilter,
    updateStatusFilter,
    resetSelection
  } = props.containersUiState

  const loadingError =
    loadingContainersStatus &&
    loadingContainersStatus.errors &&
    loadingContainersStatus.errors.length > 0 &&
    loadingContainersStatus.errors[0].code

  if (loadingError === 'not-allowed-error') {
    return (
      <div className="centerAbsolute">
        <div className={'alert alert-warning'}>You do not have sufficient access to display containers.</div>
      </div>
    )
  }

  if (loadingError === 'unknown-error') {
    return (
      <div className="centerAbsolute">
        <div className={'alert alert-danger'}>Uh-oh.. Failed to load containers</div>
      </div>
    )
  }

  const editLink = selectedContainers.length > 0 ? `/edit/${selectedContainers[0].id}` : '' // edit button is hidden if no containers are selected

  return (
    <>
      <>Total Containers: {totalCount}</>
      <div className={'card'}>
        <TableHeader
          numberOfSelectedItems={selectedContainers.length}
          selectedHeaderChildren={
            <div className="[&>*:not(:last-child)]:mr-1">
              <Link to={editLink}>
                <EditIcon className="inline-block" width="1rem" height="1rem" onClick={loadContainer} />
              </Link>
              {removeContainerStatus?.status === 'loading' ? (
                <div className="loadingSpinner text-sm" />
              ) : (
                <button onClick={_showConfirmActionDialog}>
                  <TrashIcon className="inline-block" width="1rem" height="1rem" />
                </button>
              )}
            </div>
          }
          headerChildren={
            <div className="flex text-sm [&>*:not(:last-child)]:mr-1 p-lg py-sm">
              <button className="btn btn-primary-dark min-h-3 w-full pl-1 pr-1" onClick={openAddContainerDialog}>
                <PlusIcon className="inline-block" width="1rem" height="1rem" /> Add Container
              </button>
              <FileUpload
                source="EPA"
                uploadCallback={uploadAssortmentFile}
                loading={uploadAssortmentFileStatus === 'loadingEPA'}
              />
              <FileUpload
                source="TOMRA"
                uploadCallback={uploadAssortmentFile}
                loading={uploadAssortmentFileStatus === 'loadingTOMRA'}
              />

              {CLEARING_HOUSES.map(clearingHouse => (
                <ExportButton key={clearingHouse} clearingHouse={clearingHouse}>
                  <ExportIcon className="inline-block" width="1rem" height="1rem" />
                  <span> Export {getShortName(clearingHouse)}</span>
                </ExportButton>
              ))}

              <select
                className="btn pl-1 min-h-3 text-sm"
                value={statusFilter}
                onChange={e => updateStatusFilter(e.target.value)}
              >
                <option key="all" value="All">
                  All containers
                </option>
                <option key="active" value="Active">
                  Active containers
                </option>
                <option key="inactive" value="Inactive">
                  Inactive containers
                </option>
              </select>
            </div>
          }
        />
        {uploadAssortmentFileStatus === 'failed' && (
          <div className={'alert alert-danger max-h-3'}>Failed to upload file</div>
        )}
        {removeContainerErrorFeedback && (
          <div className={'alert alert-danger max-h-3'}>{removeContainerErrorFeedback}</div>
        )}
        <ContainersList
          containers={containers}
          checkIfSelected={checkIfSelected}
          selectContainer={selectContainer}
          toggleAllContainers={toggleAllContainers}
          updateSortedColumn={updateSortedColumn}
          sortedColumn={sortedColumn}
          order={order}
          updateColumnFilter={updateColumnFilter}
          columnFilters={columnFilters}
          columns={columns}
          toggleColumn={toggleColumn}
          loadingContainers={loadingContainersStatus?.status === 'loading'}
        />
        <TableFooter
          currentPage={currentPage}
          numOfPages={numberOfPages}
          numOfItems={totalCount}
          onPageUpdate={changePage}
          recordsPerPage={recordsPerPage}
          onRecordsPerPageChange={updateRecordsPerPage}
        />
        <AddContainerDialog
          addContainerStatus={addContainerStatus}
          addContainer={addContainer}
          visible={params.action === 'add'}
          onClose={closeDialog}
          validValuesOf={validValuesOf}
        />
        {params.action === 'edit' && editingContainer && (
          <EditContainerDialog
            editContainer={editContainer}
            resetEditContainerStatus={resetEditContainerStatus}
            resetSelection={resetSelection}
            editingContainer={editingContainer}
            editContainerStatus={editContainerStatus}
            onClose={closeDialog}
            validValuesOf={validValuesOf}
          />
        )}
      </div>

      {showConfirmActionDialog &&
        createPortal(
          <ConfirmActionDialog
            onConfirm={removeSelectedContainers}
            onClose={closeConfirmActionDialog}
            message={makeConfirmActionDialogMessage()}
            header={makeConfirmActionDialogHeader()}
          />,
          document.getElementById('modal-root')!
        )}
    </>
  )
}

export const ListContainer = withRouter(
  inject('containersDataStore', 'diffDataStore', 'containersUiState')(observer(ListContainerPure))
)
