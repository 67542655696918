import { fetch as tomraFetch } from '@tomra/client-side-http-client'
import { authentication } from './authentication'

const config = {
  fetchNewToken: authentication.fetchNewToken,
  getToken: authentication.getToken
}

export function authenticatedFetch(url: string, options: any) {
  return tomraFetch(url, options, config)
    .run()
    .catch(error => {
      // Logout if refresh token is expired
      if (error.status === 401) {
        authentication.logout()
      }
      throw error
    })
}
