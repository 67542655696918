import { action, extendObservable, runInAction } from 'mobx'
import get from 'lodash/get'
import omit from 'lodash/omit'
import { HttpError } from '@tomra/client-side-http-client'

import type { Container, JsonApiError, Order, RequestProgress, ValidValues } from '../dataTypes'
import { logError } from '../lib/logError'
import {
  addContainer,
  editContainer,
  fetchContainer,
  fetchContainers,
  fetchValidValues,
  removeContainer
} from '../services/ContainersService'

function resolveApiErrorDetails(err: Error | HttpError): JsonApiError[] {
  if (get(err, 'status') === 403) {
    return [{ code: 'not-allowed-error', userMessage: '' }]
  }
  const httpErrorDetail = get(err, 'body.detail')
  if (httpErrorDetail) {
    return [{ code: 'unknown-error', userMessage: httpErrorDetail }]
  }
  const errors = get(err, 'body.errors', [{ code: '' }])
  return errors.map(e => ({ code: 'unknown-error', userMessage: e.code }))
}

export class ContainersDataStore {
  _replaceLocalContainer!: (string, Container) => void

  _validValues!: ValidValues
  validValuesOf!: (fieldName: string) => string[]
  fetchValidValues!: Function

  containers!: Container[]
  loadingContainersStatus?: RequestProgress
  fetchContainers!: (
    columnFilters?: { [columnName: string]: string },
    sortedColumn?: string,
    order?: Order,
    paginationOffset?: number,
    recordsPerPage?: number
  ) => Promise<void>
  totalCount!: number

  addContainer!: (containers: Container) => Promise<void>
  addContainerStatuses!: { [barcode: string]: RequestProgress }
  _setAddContainerStatus!: (barcode: string, value: RequestProgress) => void
  resetAddContainerStatus!: (barcode: string) => void

  fetchContainerToEdit!: (containerId: string) => Promise<void>
  editContainer!: (container: Container) => Promise<void>
  editContainerStatus?: RequestProgress
  editingContainer?: Container
  _setEditContainerStatus!: (value: RequestProgress) => void
  resetEditContainerStatus!: () => void
  _setEditingContainer!: (container: Container) => void

  removeContainer!: (containerId: string) => Promise<void>
  removeContainers!: (containerIds: string[]) => Promise<void>
  removeContainerStatus?: RequestProgress
  _setRemoveContainerStatus!: (value: RequestProgress) => void
  _resetRemoveContainerStatus!: () => void

  constructor() {
    extendObservable<ContainersDataStore, ContainersDataStore>(this, {
      containers: [],
      loadingContainersStatus: undefined,
      totalCount: 0,

      addContainerStatuses: {},
      editContainerStatus: undefined,
      editingContainer: undefined,
      removeContainerStatus: undefined,

      _validValues: {},

      validValuesOf: fieldName => {
        return this._validValues[fieldName] || []
      },

      fetchValidValues: action(async () => {
        try {
          const validValues = await fetchValidValues()

          runInAction(() => {
            this._validValues = {
              ...validValues,
              newProduct: [true, false],
              nswRefundLogo: [true, false],
              santRefundLogo: [true, false]
            }
          })
        } catch (err: any) {
          if (err.status !== 403) {
            logError(`Failed to fetch validValues for assortment containers.`, err)
          }
          throw err
        }
      }),

      fetchContainers: action(
        async (
          columnFilters: { [columnName: string]: string } = {},
          sortedColumn = '',
          order = 'desc',
          paginationOffset = 0,
          recordsPerPage = 30
        ) => {
          this.loadingContainersStatus = { status: 'loading' }

          try {
            const result = await fetchContainers(columnFilters, sortedColumn, order, paginationOffset, recordsPerPage)
            runInAction(() => {
              this.containers = result.data
              this.totalCount = result.meta.totalCount
              this.loadingContainersStatus = { status: 'loaded' }
            })
          } catch (err: any) {
            runInAction(() => {
              this.loadingContainersStatus = { status: 'error', errors: resolveApiErrorDetails(err) }
            })
            throw err
          }
        }
      ),

      fetchContainerToEdit: async containerId => {
        this._setEditContainerStatus({ status: 'loading' })

        try {
          const matchingContainer = this.containers.find(container => container.id === containerId)

          if (matchingContainer !== undefined) {
            this._setEditingContainer(matchingContainer)
            this.resetEditContainerStatus()
          } else {
            const result = await fetchContainer(containerId)

            this.resetEditContainerStatus()
            this._setEditingContainer(result)
          }
        } catch (err: any) {
          logError(`Could not fetch container #${containerId} to edit.`, err)

          this._setEditContainerStatus({ status: 'error', errors: resolveApiErrorDetails(err) })
          throw err
        }
      },

      addContainer: async container => {
        try {
          this._setAddContainerStatus(container.barcode, { status: 'loading' })
          await addContainer(container)
          this.resetAddContainerStatus(container.barcode)
        } catch (err: any) {
          logError('Error while adding container', err)

          this._setAddContainerStatus(container.barcode, { status: 'error', errors: resolveApiErrorDetails(err) })

          // re-throwing to allow using code to have control flow based on success/failure of this operation
          throw err
        }
      },

      editContainer: async (container: Container) => {
        this._setEditContainerStatus({ status: 'loading' })

        try {
          const response = await editContainer(container)
          const updatedContainer = response.data
          if (!updatedContainer || !updatedContainer.id) {
            throw new Error('editContainer did not return a valid container')
          }

          this._replaceLocalContainer(container.id, updatedContainer)
          this.resetEditContainerStatus()
        } catch (err: any) {
          logError(`Could not edit container #${container.id}.`, err)

          this._setEditContainerStatus({ status: 'error', errors: resolveApiErrorDetails(err) })

          throw err
        }
      },

      removeContainer: containerId => {
        return this.removeContainers([containerId])
      },

      removeContainers: async containerIds => {
        this._setRemoveContainerStatus({ status: 'loading' })
        let currentContainerId = ''
        try {
          for (currentContainerId of containerIds) {
            const result = await removeContainer(currentContainerId)
            const removedContainer = result.data

            this._replaceLocalContainer(currentContainerId, removedContainer)
          }
          this._resetRemoveContainerStatus()
        } catch (err: any) {
          logError(`Error while removing container '${currentContainerId}'`, err)

          this._setRemoveContainerStatus({ status: 'error', errors: resolveApiErrorDetails(err) })

          // re-throwing to allow using code to have control flow based on success/failure of this operation
          throw err
        }
      },

      _setAddContainerStatus: action((barcode, value) => {
        this.addContainerStatuses = { ...this.addContainerStatuses, [barcode]: value }
      }),

      resetAddContainerStatus: action(barcode => {
        this.addContainerStatuses = omit(this.addContainerStatuses, barcode)
      }),

      _setEditContainerStatus: action(newStatus => {
        this.editContainerStatus = newStatus
      }),

      _setEditingContainer: action(container => {
        this.editingContainer = container
      }),

      resetEditContainerStatus: action(() => {
        this.editContainerStatus = undefined
      }),

      _setRemoveContainerStatus: action(newStatus => {
        this.removeContainerStatus = newStatus
      }),

      _resetRemoveContainerStatus: action(() => {
        this.removeContainerStatus = undefined
      }),

      _replaceLocalContainer: action((id, newContainer: Container) => {
        const containerIdx = this.containers.findIndex(container => container.id === id)

        if (containerIdx !== -1) {
          this.containers[containerIdx] = newContainer
        }
      })
    })
  }
}
