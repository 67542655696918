import { authenticatedFetch } from '../lib/authenticatedFetch'

import { API_HOST, CLEARING_HOUSES } from '../lib/environment'

import type { Container, ChangedContainer, ValidationMessage, ValidValues, Order } from '../dataTypes'

export type DiffResponse = {
  validationStatus: {
    valid: boolean
    validationMessages: ValidationMessage[]
  }
  added: Container[]
  removed: Container[]
  updated: ChangedContainer[]
}

export type ContainersResponse = {
  meta: { totalCount: number }
  data: Container[]
}

function getData(response) {
  if (!response) {
    throw new Error('No response data!')
  }
  if (!response.data) {
    throw new Error(`No data attribute in the response (${response})`)
  }
  return response.data
}

function fetchFromAPI(relativeUrl: string, options?: Object): Promise<any> {
  return authenticatedFetch(`${API_HOST}assortment/${CLEARING_HOUSES[0]}${relativeUrl}`, options)
}

export function addContainer(container: Container) {
  return fetchFromAPI(`/containers`, { method: 'POST', body: JSON.stringify(container) })
}

export function removeContainer(id: string) {
  return fetchFromAPI(`/containers/${id}`, { method: 'DELETE' })
}

export function editContainer(container: Container) {
  return fetchFromAPI(`/containers/${container.id}`, { method: 'PUT', body: JSON.stringify(container) })
}

export function fetchContainers(
  columnFilters: { [columnName: string]: string },
  orderBy: string,
  orderByDirection: Order,
  offset: number,
  limit: number
): Promise<ContainersResponse> {
  const filterString = Object.entries(columnFilters).reduce(
    (acc, [name, value]) => acc + `filter[${name}]=${value}&`,
    ''
  )
  const direction = orderByDirection === 'desc' ? '-' : ''
  const sortString = orderBy ? `sort=${direction}${orderBy}&` : ''
  return fetchFromAPI(`/containers?${filterString}${sortString}offset=${offset}&limit=${limit}`)
}

export function fetchContainer(containerId: string): Promise<Container> {
  return fetchFromAPI(`/containers/${containerId}`).then(getData)
}

export function fetchValidValues(): Promise<ValidValues> {
  return fetchFromAPI('/containers/validvalues').then(getData)
}

export function uploadAssortmentFile(file: File, source: 'EPA' | 'TOMRA'): Promise<DiffResponse> {
  const data = new FormData()
  data.append('uploaded_file', file)
  return fetchFromAPI(`/diff/${source}/compute`, { method: 'POST', body: data }).then(getData)
}
