import React from 'react'
import { defaultToOutput } from '../../../../stores/containerColumnMap'
import type { ContainerColumn } from '../../../../dataTypes'
import styled from 'styled-components'
import { colors, spacing } from '../../../../styles'
import { TableTextField } from '../../containers/TableTextField'
import { TableSelect } from '../../containers/TableSelect'

type Props = {
  column: ContainerColumn
  value: string
  onChange: Function
  validValuesOf: Function
}

export const SelectOption = styled.div`
  cursor: pointer;
  min-width: 100%;
  transition: 200ms;
  padding: ${spacing.sm} ${spacing.md};

  :not(:first-child) {
    border-top: 1px solid ${colors.GREY};
  }

  :hover {
    background-color: ${colors.GREY};
  }
`

export const AddedRowTd = (props: Props) => {
  const { column, value, onChange, validValuesOf } = props
  const { fieldName, mutable } = column
  const validValuesOfField = validValuesOf(fieldName) || []

  const toOutput = column.toOutput || defaultToOutput
  const formattedValue = toOutput(value)

  if (!mutable) {
    return <td>{formattedValue}</td>
  }

  if (validValuesOfField.length !== 0) {
    return (
      <td>
        <TableSelect selectedValue={formattedValue}>
          {validValuesOfField.map(value => (
            <SelectOption key={value} onClick={() => onChange(fieldName, value)}>
              {value}
            </SelectOption>
          ))}
        </TableSelect>
      </td>
    )
  }

  return (
    <td>
      <TableTextField value={formattedValue} onChange={value => onChange(fieldName, value)} />
    </td>
  )
}
