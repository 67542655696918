import React from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../../../styles'
import { RecordsPerPageSelect } from './RecordsPerPageSelect'
import { ReactComponent as ArrowLeftIcon } from '@tomra/design-system/src/config/icons/arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from '@tomra/design-system/src/config/icons/arrow-right.svg'

const Container = styled.div`
  display: flex;
  align-items: center;
  min-height: 54px;
  color: ${colors.SECONDARY_TEXT};
  padding-left: ${spacing.lg};
  padding-right: ${spacing.lg};
  > *:not(:first-child) {
    margin-right: ${spacing.lg};
  }
  @media (max-width: 991px) {
    padding-left: ${spacing.md};
    padding-right: ${spacing.md};
  }
`

type Props = {
  currentPage: number
  numOfPages: number
  numOfItems?: number
  recordsPerPage?: number
  onPageUpdate: Function
  onRecordsPerPageChange?: Function
}

type State = {}

export class TableFooter extends React.Component<Props, State> {
  _nextPage = () => {
    if (this.props.currentPage !== this.props.numOfPages) {
      const newPage = this.props.currentPage + 1
      this.props.onPageUpdate(newPage)
    }
  }

  _previousPage = () => {
    if (this.props.currentPage !== 1) {
      const newPage = this.props.currentPage - 1
      this.props.onPageUpdate(newPage)
    }
  }

  render() {
    const { currentPage, numOfPages, numOfItems, onRecordsPerPageChange, recordsPerPage } = this.props

    return (
      <Container>
        <div>
          <button className="btn btn-icon" onClick={this._previousPage} disabled={currentPage === 1}>
            <ArrowLeftIcon className="inline-block" width="1rem" height="1rem" />
          </button>
          <small className="pr-1">
            Page {currentPage} of {numOfPages}
          </small>
          <button className="btn btn-icon" onClick={this._nextPage} disabled={currentPage >= numOfPages}>
            <ArrowRightIcon className="inline-block" width="1rem" height="1rem" />
          </button>
        </div>
        {numOfItems && <small>Total items: {numOfItems} </small>}
        {recordsPerPage && onRecordsPerPageChange && (
          <RecordsPerPageSelect
            recordsPerPage={recordsPerPage}
            recordsPerPageOptions={[30, 60, 100]}
            onChange={onRecordsPerPageChange}
          />
        )}
      </Container>
    )
  }
}
