/* eslint no-console: 0 */

export function logError(error, cause, metadata) {
  if (cause instanceof Error && error instanceof Error) {
    error.message += `, Caused by: ${cause.toString()}`
    error.stack += '\nCaused by: ' + cause.stack
  }

  const context = { metadata }

  if (window.DD_RUM) {
    window.DD_RUM.addError(error, context)
  } else {
    console.error(error)
  }
}
