import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { colors, spacing, radiuses } from '../../../styles'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Input = styled.input<{ valid: boolean; touched: boolean }>`
  background-color: ${props => (!props.valid && props.touched ? colors.LIGHT_RED : 'white')};
  color: ${props => (!props.valid && props.touched ? colors.DARK_RED : 'black')};
  padding: ${spacing.sm};
  border-radius: ${radiuses.RADIUS_DEFAULT};
  border: none;
  transition: 200ms;
  width: 100%;
  cursor: pointer;
  ::placeholder {
    color: ${props => (!props.valid && props.touched ? colors.DARK_RED : colors.PRIMARY_TEXT)};
  }
  :hover {
    background-color: ${props => (!props.valid && props.touched ? colors.LIGHT_RED : colors.GREY)};
  }
  :focus {
    outline: 0;
    background-color: ${props => (!props.valid && props.touched ? colors.LIGHT_RED : colors.GREY)};
  }
`

export const ErrorMessage = styled.p`
  margin: ${spacing.xs} 0px;
  color: ${colors.RED};
  text-align: left;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`

type Props = {
  onChange: Function
  value?: string
  valid: boolean
  pattern?: string | RegExp
  errorMsg?: string
  children?: ReactElement
  required?: boolean
  innerRef?: Function
}

type State = {
  touched: boolean
}

export class TableTextField extends React.Component<Props, State> {
  static defaultProps = {
    valid: true,
    onChange: () => {}
  }

  state = {
    touched: false
  }

  _onChange = evt => {
    const { onChange, pattern, required } = this.props
    const newValue = evt.target.value

    if (pattern) {
      const isValid = new RegExp(pattern).exec(newValue)

      onChange(newValue, !!isValid) // cast isValid to true bool
    } else if (required && newValue === '') {
      onChange(newValue, false)
    } else {
      onChange(newValue, true)
    }
  }

  _onBlur = evt => {
    this.setState({ touched: true })
    this._onChange(evt)
  }

  render() {
    const { children, valid, errorMsg } = this.props
    const defaultMsg = this.props.required ? 'This field is required' : 'Invalid input'

    return (
      <Container>
        <Input
          value={this.props.value}
          onChange={this._onChange}
          onBlur={this._onBlur}
          valid={valid}
          touched={this.state.touched}
        />

        {children}

        {!this.props.valid && this.state.touched ? <ErrorMessage>{errorMsg || defaultMsg}</ErrorMessage> : null}
      </Container>
    )
  }
}
