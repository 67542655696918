import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { ChangedRow } from './ChangedRow'

import type { ChangedContainer, ContainerColumn, Order } from '../../../../dataTypes'
import { Container } from '../../../../dataTypes'
import { SortableTh } from '../../containers/SortableTh'
import { ColumnSelect } from '../../containers/ColumnSelect'
import { ReactComponent as MenuHorizontal } from '@tomra/design-system/src/config/icons/menu-horizontal.svg'

const ColumnSelectTh = styled('th')`
  position: relative;
  width: 40px;
  > button {
    padding: 0;
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    z-index 1;
  }
`

const Legend = styled.abbr`
  display: inline-block;
  font-size: 1.4em;
  line-height: 1.8ex;
  border-radius: 1.2em;
  margin-right: 4px;
  text-align: center;
  margin: 0.5em;
  border: 1px solid black;
  width: 1em;
  text-decoration: none !important;
  cursor: help;
`

const FixedHeaderWidthTable = styled.table`
  table-layout: auto;
  th {
    min-width: 70px;
    max-width: none;
  }

  td {
    max-width: none;
  }
`

export type Props = {
  changedContainers: ChangedContainer[]
  updateSortedColumn: Function
  sortedColumn?: string
  order?: Order
  columns: ContainerColumn[]
  toggleColumn: Function
  editContainer: (container: Container) => Promise<void>
  removeContainerFromChangedDiff: (string) => void
  validValuesOf: Function
}

type State = {
  showEditColumns: boolean
}

class ChangedListPure extends React.Component<Props, State> {
  state = {
    showEditColumns: false
  }

  _toggleEditColumns = () => {
    this.setState(prevState => {
      return { showEditColumns: !prevState.showEditColumns }
    })
  }

  render() {
    const {
      changedContainers,
      updateSortedColumn,
      sortedColumn,
      order,
      columns,
      toggleColumn,
      editContainer,
      removeContainerFromChangedDiff,
      validValuesOf
    } = this.props

    return (
      <div>
        <Legend title="Values changed from master are bold; updates from import are underlined in blue">?</Legend>
        <FixedHeaderWidthTable className={'table'}>
          <thead>
            <tr>
              <ColumnSelectTh>
                <button onClick={this._toggleEditColumns}>
                  <MenuHorizontal className="inline-block" width="1rem" height="1rem" />
                </button>
                {this.state.showEditColumns && (
                  <ColumnSelect columns={columns} toggleColumn={toggleColumn} close={this._toggleEditColumns} />
                )}
              </ColumnSelectTh>
              {columns.map(column => {
                return (
                  column.visible && (
                    <SortableTh
                      key={column.fieldName}
                      name={column.fieldName}
                      onClick={updateSortedColumn}
                      order={sortedColumn === column.fieldName && order!}
                    >
                      {column.label}
                    </SortableTh>
                  )
                )
              })}
              <th />
            </tr>
          </thead>
          <tbody>
            {changedContainers.length > 0 ? (
              changedContainers.map(container => (
                <ChangedRow
                  key={container.newValue.barcode}
                  columns={columns}
                  container={container}
                  editContainer={editContainer}
                  removeContainerFromChangedDiff={removeContainerFromChangedDiff}
                  validValuesOf={validValuesOf}
                />
              ))
            ) : (
              <tr>
                <td colSpan={2}>No changed containers</td>
              </tr>
            )}
          </tbody>
        </FixedHeaderWidthTable>
      </div>
    )
  }
}

export const ChangedList = observer(ChangedListPure)
