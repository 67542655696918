import React from 'react'

import { defaultToOutput } from '../../../stores/containerColumnMap'
import type { Container, ContainerColumn } from '../../../dataTypes'
import { colors } from '../../../styles'
import styled from 'styled-components'

type Props = {
  container: Container
  isSelected: boolean
  selectContainer: Function
  visibleColumns: ContainerColumn[]
  outdated: boolean
}

const SelectableTr = styled.tr<{ isSelected: boolean }>`
  border-top: 1px solid ${props => (props.isSelected ? 'white' : colors.GREY)};
  border-bottom: 1px solid ${props => (props.isSelected ? 'white' : colors.GREY)};
  transition: 200ms;
  background-color: ${props => (props.isSelected ? colors.GREY : 'initial')};
`

export function ContainersListRow({ container, isSelected, selectContainer, visibleColumns, outdated }: Props) {
  return (
    <SelectableTr key={container.id} onClick={() => selectContainer(container)} isSelected={isSelected}>
      <td>
        <input type="checkbox" checked={isSelected} readOnly />
      </td>
      {visibleColumns.map(column => {
        const toOutput = column.toOutput || defaultToOutput
        const output = toOutput(container[column.fieldName])
        const style = outdated ? { color: colors.LIGHT_GREY } : {}

        return (
          <td key={column.fieldName} style={style}>
            {output}
          </td>
        )
      })}
    </SelectableTr>
  )
}
