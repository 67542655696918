import { action, extendObservable } from 'mobx'

import type { Container, ContainerUserRegisteredFields as Fields } from '../dataTypes'

const materialCategoryToShapeGroupMap: { [key: string]: number } = {
  ALUM: 710,
  STEEL: 720,
  GLASS: 700,
  PET: 700,
  HDPE: 700,
  LPB: 700,
  OP: 700,
  OM: 700
}

const materialCategoryToSortCodeMap: { [key: string]: number } = {
  ALUM: 10,
  STEEL: 11,
  GLASS: 12,
  PET: 13,
  HDPE: 14,
  LPB: 15,
  OP: 16,
  OM: 17
}

const materialTypeToCategoryMap: { [key: string]: string } = {
  Glass: 'GLASS',
  PET: 'PET',
  Aluminium: 'ALUM',
  HDPE: 'HDPE',
  'LPB Aseptic (UHT or long life packs)': 'LPB',
  'LPB Gable top': 'LPB',
  'Steel (cans)': 'STEEL',
  LDPE: 'OP',
  PVC: 'OP',
  'PLA Bio Polymer': 'OP',
  'Cask (cardboard, foil and/or plastic)': 'OM',
  Other: 'OM'
}

const sortCodeMap = Object.entries(materialTypeToCategoryMap).reduce(
  (acc, [materialType, materialCategory]) => ({
    ...acc,
    [materialType]: materialCategoryToSortCodeMap[materialCategory]
  }),
  {}
)

const shapeGroupMap = Object.entries(materialTypeToCategoryMap).reduce(
  (acc, [materialType, materialCategory]) => ({
    ...acc,
    [materialType]: materialCategoryToShapeGroupMap[materialCategory]
  }),
  {}
)

const initialFields: Fields = {
  barcode: '',
  productName: '',
  heightWithCapMm: null,
  heightOverride: null,
  largestDiameterMm: null,
  diameterOverride: null,
  weightEmptyGm: null,
  capacityMl: null,
  shapeGroup: null,
  sortCode: null,
  materialType: '',
  toleranceClass: 0
}

export class AddContainerUiState {
  // Observables
  fields!: Fields

  // getters
  shapeGroupWithDefault!: number
  isDummyBarcode!: boolean
  prefilledSortCode!: number
  parsedFields!: Container

  // Actions
  setField!: (name: string, value: string | number) => void
  setNumericField!: (name: string, value: string) => void
  resetFields!: Function

  // Object to satisfy flow during tests
  constructor() {
    extendObservable(this, {
      fields: initialFields,

      get isDummyBarcode() {
        return this.fields.barcode.startsWith('000000')
      },

      get prefilledSortCode() {
        return this.fields.materialType ? sortCodeMap[this.fields.materialType] : null
      },

      get shapeGroupWithDefault() {
        return this.fields.shapeGroup
          ? this.fields.shapeGroup
          : this.fields.materialType
          ? shapeGroupMap[this.fields.materialType]
          : null
      },

      get parsedFields() {
        const sortCode = this.isDummyBarcode ? this.fields.sortCode : this.prefilledSortCode
        return {
          ...this.fields,
          shapeGroup: this.shapeGroupWithDefault,
          sortCode,
          source: 'TOMRA',
          active: true
        }
      },

      setField: action((name, value) => {
        this.fields[name] = value
      }),

      setNumericField: (name, value) => {
        this.setField(name, parseInt(value, 10))
      },

      resetFields: action(() => {
        this.fields = initialFields
      })
    })
  }
}
