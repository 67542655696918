import localforage from 'localforage'
import { AuthStore, WebStorage } from '@tomra/client-side-authentication'
import { KEYCLOAK_HOST } from './environment'

import { getLocaleForKeycloak } from './locale'

const storage: WebStorage = {
  setItem: localforage.setItem,
  removeItem: localforage.removeItem,
  clear: localforage.clear,
  getItem: localforage.getItem
}

export const authentication = new AuthStore(
  'TomraConnectUsers',
  'barcode-manager-ui',
  KEYCLOAK_HOST,
  getLocaleForKeycloak(),
  storage
)
