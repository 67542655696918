import type { ContainerColumn } from '../dataTypes'

// toOutput functions

export function defaultToOutput(value: any): string {
  return value !== null && value !== undefined ? String(value) : ''
}

function boolToYesNo(value: any) {
  return value === true ? 'Yes' : value === false ? 'No' : ''
}

// fromInput functions

function defaultFromInput(filterString: string): string {
  return filterString
}

function yesNoToBool(input: string) {
  const lowerCaseInput = input.toLowerCase()
  if (lowerCaseInput.charAt(0) === 'y') return true
  if (lowerCaseInput.charAt(0) === 'n') return false
  return ''
}

function stringToInt(input: string) {
  if (!input) {
    return null
  }
  return parseInt('' + input, 10)
}

export const containerColumnMap: ContainerColumn[] = [
  { fieldName: 'source', label: 'Source', mutable: false, visible: true },
  { fieldName: 'barcode', label: 'Barcode', mutable: false, visible: true },
  { fieldName: 'productName', label: 'Product Name', mutable: true, visible: true },
  { fieldName: 'materialType', label: 'Material Type', mutable: true, visible: true },
  {
    fieldName: 'heightWithCapMm',
    label: 'Height',
    fullTitle: 'Height With Cap mm',
    mutable: true,
    visible: true,
    fromInput: stringToInt
  },
  { fieldName: 'heightOverride', label: 'Height Override', mutable: true, visible: true, fromInput: stringToInt },
  {
    fieldName: 'largestDiameterMm',
    label: 'Diameter',
    fullTitle: 'Largest Diameter mm',
    mutable: true,
    visible: true,
    fromInput: stringToInt
  },
  { fieldName: 'diameterOverride', label: 'Diameter Override', mutable: true, visible: true, fromInput: stringToInt },
  {
    fieldName: 'weightEmptyGm',
    label: 'Weight',
    fullTitle: 'Weight Empty grams',
    mutable: true,
    visible: true,
    fromInput: stringToInt
  },
  {
    fieldName: 'capacityMl',
    label: 'Capacity',
    fullTitle: 'Capacity ml',
    mutable: true,
    visible: true,
    fromInput: stringToInt
  },
  { fieldName: 'shapeGroup', label: 'Shape Group', mutable: true, visible: false, fromInput: stringToInt },
  { fieldName: 'sortCode', label: 'Sort Code', mutable: true, visible: false, fromInput: stringToInt },
  {
    fieldName: 'active',
    label: 'Active',
    mutable: false,
    visible: false,
    toOutput: boolToYesNo,
    fromInput: yesNoToBool
  },
  { fieldName: 'supplierName', label: 'Supplier Name', mutable: true, visible: false },
  { fieldName: 'materialCategory', label: 'Material Category', mutable: false, visible: false },
  { fieldName: 'materialGroup', label: 'Material Group', mutable: false, visible: false },
  { fieldName: 'materialTypeOther', label: 'Material (other)', mutable: true, visible: false },
  { fieldName: 'productGroup', label: 'Product Group', mutable: true, visible: false },
  { fieldName: 'productGroupOther', label: 'Product Group (other)', mutable: true, visible: false },
  { fieldName: 'glassColour', label: 'Glass Color', mutable: true, visible: false },
  { fieldName: 'glassColourOther', label: 'Glass Color (other)', mutable: true, visible: false },
  { fieldName: 'petColour', label: 'Pet Color', mutable: true, visible: false },
  { fieldName: 'registrationStatus', label: 'Registration Status', mutable: true, visible: false },
  { fieldName: 'toleranceClass', label: 'Tolerance Class', mutable: true, visible: false, fromInput: stringToInt },
  { fieldName: 'san', label: 'San', mutable: true, visible: false },
  {
    fieldName: 'newProduct',
    label: 'New',
    mutable: true,
    visible: false,
    toOutput: boolToYesNo,
    fromInput: yesNoToBool
  },
  { fieldName: 'newProductLaunchDate', label: 'New Date', mutable: true, visible: false },
  {
    fieldName: 'nswRefundLogo',
    label: 'NSW Refund Logo',
    mutable: true,
    visible: false,
    toOutput: boolToYesNo,
    fromInput: yesNoToBool
  },
  {
    fieldName: 'santRefundLogo',
    label: 'Sant Refund Logo',
    mutable: true,
    visible: false,
    toOutput: boolToYesNo,
    fromInput: yesNoToBool
  }
]

type FunctionMap = { [key: string]: (string) => string }

export const fromInputPerField: FunctionMap = containerColumnMap.reduce((acc, current: ContainerColumn) => {
  acc[current.fieldName] = current.fromInput || defaultFromInput
  return acc
}, {})

export default containerColumnMap
