import React from 'react'
import styled from 'styled-components'
import { ChangedRowTd } from './ChangedRowTd'
import { spacing } from '../../../../styles'

import type { ChangedContainer, Container, ContainerColumn } from '../../../../dataTypes'

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-left: ${spacing.md};
  }
`

type Props = {
  container: ChangedContainer
  columns: ContainerColumn[]
  editContainer: (container: Container) => Promise<void>
  removeContainerFromChangedDiff: (string) => void
  validValuesOf: Function
}

type State = {
  correctedContainer: Container
  loading: boolean
  error?: Error
}

export class ChangedRow extends React.Component<Props, State> {
  state: Readonly<State> = {
    correctedContainer: { ...this.props.container.newValue },
    loading: false
  }

  _onChange = (fieldName, value) => {
    const correctedContainer = this.state.correctedContainer
    correctedContainer[fieldName] = value

    this.setState({ correctedContainer })
  }

  _editContainer = async () => {
    try {
      this.setState({ loading: true, error: undefined })
      await this.props.editContainer(this.state.correctedContainer)
      this._removeContainerFromChangedDiff()
      this.setState({ loading: false })
    } catch (error: any) {
      this.setState({ loading: false, error })
    }
  }

  _removeContainerFromChangedDiff = () => {
    this.props.removeContainerFromChangedDiff(this.state.correctedContainer.id)
  }

  render() {
    const { container, columns, validValuesOf } = this.props
    const { correctedContainer, loading, error } = this.state

    return (
      <tr key={container.newValue.id}>
        <td />
        {columns.map(
          column =>
            column.visible && (
              <ChangedRowTd
                key={column.fieldName}
                column={column}
                oldValue={container.oldValue[column.fieldName]}
                newValue={container.newValue[column.fieldName]}
                correctedValue={correctedContainer[column.fieldName]}
                onChange={value => this._onChange(column.fieldName, value)}
                validValuesOf={validValuesOf}
              />
            )
        )}
        <td>
          <ActionContainer>
            {error && <div className={'alert alert-danger'}>Update failed.</div>}
            <button className="btn btn-primary-dark" onClick={this._editContainer}>
              {loading ? <div className="loadingSpinner text-sm" /> : 'Add'}
            </button>
            <button className="btn btn-primary-danger" onClick={this._removeContainerFromChangedDiff}>
              Decline
            </button>
          </ActionContainer>
        </td>
      </tr>
    )
  }
}
