import React from 'react'

import type { ValidationMessage } from '../../../../dataTypes'

type Props = {
  messages: ValidationMessage[]
}

export const InvalidList = (props: Props) => (
  <div>
    {props.messages.map(message => (
      <div key={`${message.itemNum}-${message.message}`}>
        <div>
          (L{message.itemNum}) {message.severity}: {message.message}
        </div>
        <div>
          Full line: <pre>{message.item}</pre>
        </div>
      </div>
    ))}
    {props.messages.length === 0 && 'No invalid containers'}
  </div>
)
